/* eslint-disable import/first */
require('dotenv').config();

import React, { useCallback, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { TmsUIProvider } from '@kargotech/tms-ui';
import { AuthProvider } from '@kargotech/tms-core/auth';
import FlagProvider from '@unleash/proxy-client-react';
import { Button } from '@kargotech/tms-ui/components';

import { DownloadOutlined } from '@ant-design/icons';
import usePWAPrompt from '~/Hooks/usePWAPrompt'; // Assuming you saved the custom hook in usePWAPrompt.js

import {
  APP_CONTEXT_STORAGE_KEY,
  BANNER_STORAGE_KEY,
  COACH_MARK_STORAGE_KEY,
  FREE_TRIAL_LOGIN_FLAG,
  LAST_ANNOUNCEMENT_TIMESTAMP_KEY,
} from '~/Configurations/constants';
import theme from '~/Configurations/theme';
import Routes from '~/Configurations/Routes';
import UNLEASH_CONFIGS from '~/Configurations/unleash';
import AppProvider from '~/Contexts/AppProvider';
import ApolloClientProvider from '~/Contexts/ApolloClientProvider';
import ProfileProvider from '~/Contexts/ProfileProvider';
import NavigationProvider from '~/Contexts/NavigationProvider';
import NftModulesProvider from '~/Contexts/NftModulesProvider';
import { APOLLO_CLIENTS } from '~/Services/apollo';
import { remoteConfig } from './Services/firebase';
import GlobalStyle from './Components/GlobalStyle';
import NotificationProvider from './Contexts/NotificationProvider';

export function UIWrapper({ children }) {
  const { i18n } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <TmsUIProvider i18n={i18n}>
        {children}
        <GlobalStyle />
      </TmsUIProvider>
    </ThemeProvider>
  );
}

export function AppWrapper({ children }) {
  const handleLogout = useCallback(() => {
    Object.keys(APOLLO_CLIENTS).forEach(apolloClient => {
      const cache = get(APOLLO_CLIENTS, `${apolloClient}.cache`);
      if (cache) {
        cache.reset();
      }
    });
  }, []);

  return (
    <UIWrapper>
      <AppProvider>
        <AuthProvider
          apiClient={APOLLO_CLIENTS.PROFILE}
          onLogoutSuccess={handleLogout}
          preservedLocalStorageKeys={[
            APP_CONTEXT_STORAGE_KEY,
            BANNER_STORAGE_KEY,
            ...Object.values(COACH_MARK_STORAGE_KEY),
            FREE_TRIAL_LOGIN_FLAG,
            LAST_ANNOUNCEMENT_TIMESTAMP_KEY,
          ]}
        >
          <ApolloClientProvider>
            <FlagProvider config={UNLEASH_CONFIGS}>
              <ProfileProvider>
                <NavigationProvider>
                  <NftModulesProvider>{children}</NftModulesProvider>
                </NavigationProvider>
              </ProfileProvider>
            </FlagProvider>
          </ApolloClientProvider>
        </AuthProvider>
      </AppProvider>
    </UIWrapper>
  );
}

export default function App() {
  const { handleInstallClick, canInstall } = usePWAPrompt();

  useEffect(() => {
    remoteConfig.fetchAndActivate();
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js');
      navigator.serviceWorker.register('/firebase-messaging-sw.js');
    }

    const initNotification = async () => {
      await Notification.requestPermission();
    };

    if ('Notification' in window) {
      initNotification();
    }
  }, []);

  return (
    <AppWrapper>
      {canInstall ? (
        <BannerContainer>
          <InstallMessage>Install app for a better experience.</InstallMessage>
          <Button
            icon={<DownloadOutlined />}
            onClick={e => {
              e.preventDefault();
              handleInstallClick();
            }}
            style={{ width: '100%' }}
            type="button"
          >
            Install Now
          </Button>
        </BannerContainer>
      ) : null}
      <Router>
        <NotificationProvider>
          <Routes />
        </NotificationProvider>
      </Router>
    </AppWrapper>
  );
}

const BannerContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 8px;
  background: #fbf0ce;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 12px;
`;

const InstallMessage = styled.p`
  margin: 0;
`;

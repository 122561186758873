/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Button, Grid } from '@kargotech/tms-ui/components';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import useFcmToken from '~/Hooks/useFcmToken';
import { ProfileContext } from './ProfileProvider';
import { APOLLO_CLIENTS } from '~/Services/apollo';
import 'firebase/messaging';

import ADD_RECIPIENT_DEVICE_KEY from '~/GraphQL/EventService/Mutations/addRecipientDeviceKey';
import { EVENT_SERVICE_DEVICE_TYPE, FCM_STORAGE_KEY } from '~/Configurations/constants';

const NOTIFICATION_INITIAL_STATE = { isOpen: false, messageData: {} };

function NotificationProvider({ children }) {
  const location = useLocation();
  const history = useHistory();
  const { profile } = useContext(ProfileContext);
  const { retrieveToken, fcmToken } = useFcmToken({ isLogin: !!profile.ksuid });
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isDesktop = !!screens.md;

  const [notificationState, setNotificationState] = useState(NOTIFICATION_INITIAL_STATE);
  const [counter, setCounter] = useState(0);

  const [addRecipientToken] = useMutation(ADD_RECIPIENT_DEVICE_KEY, {
    client: APOLLO_CLIENTS.EVENT,
  });

  const handleViewLater = () => {
    setNotificationState(NOTIFICATION_INITIAL_STATE);
  };

  const handleClickGhostComponent = () => {
    setCounter(counter + 1);
  };

  const handleOpenNotification = () => {
    if (notificationState.messageData) {
      let formattedUrl = '/'; // default to root page
      const code = notificationState.messageData?.data?.code;
      const dataPayload = notificationState.messageData?.data?.payload;
      if (dataPayload) {
        const formattedDataPayload = JSON.parse(`${dataPayload.replace(/\\/g, '')}`);
        formattedUrl = `/job-redirection?jobKsuid=${formattedDataPayload?.job_ksuid}&code=${code}`;
        history.push(formattedUrl);
      } else {
        history.replace(formattedUrl);
      }
    } else {
      history.replace('/');
    }
    setNotificationState(NOTIFICATION_INITIAL_STATE);
  };

  const renderNotificationPopup = () => {
    if (notificationState.isOpen && notificationState.messageData) {
      return (
        <>
          <StyledBackdrop onClick={handleViewLater} />
          <StyledPopup isDesktop={isDesktop}>
            <StyledFlexRow>
              <h2 style={{ fontSize: '1.25rem' }}>{notificationState?.messageData?.notification?.title}</h2>
            </StyledFlexRow>
            <StyledText>{notificationState?.messageData?.notification?.body}</StyledText>
            <StyledButtonContainer>
              <Button block={true} onClick={handleOpenNotification} type="default">
                Visit
              </Button>
              <Button
                block={true}
                onClick={handleViewLater}
              >
                View Later
              </Button>
            </StyledButtonContainer>
          </StyledPopup>
        </>
      );
    }
    return null;
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const isSupported = firebase.messaging.isSupported();
    if (isSupported) {
      const messaging = firebase.messaging();

      const unsubscribe = messaging.onMessage(payload => {
        setNotificationState({
          isOpen: true,
          messageData: payload,
        });
      });

      // Clean up the listener when the component is unmounted
      return () => {
        unsubscribe();
      };
    }
  }, [setNotificationState]); // Ensure dependencies are properly passed

  // Only retrieve FCM token if user already login
  // every path change, retrieve token
  useEffect(() => {
    if (location.pathname && !!profile.ksuid) {
      retrieveToken();
    }
  }, [location.pathname, profile.ksuid, retrieveToken]);

  /**
   * Only register token if new token created, or storedToken is different from new token
   */
  useEffect(() => {
    const handleFcmToken = () => {
      const storedFcmToken = localStorage.getItem(FCM_STORAGE_KEY);

      // Check if the stored token is different from the current token
      const isTokenUpdated = !storedFcmToken || storedFcmToken !== fcmToken;

      if (isTokenUpdated && !!fcmToken) {
        addRecipientToken({
          variables: {
            deviceToken: fcmToken,
            deviceType: EVENT_SERVICE_DEVICE_TYPE,
          },
        });
        localStorage.setItem(FCM_STORAGE_KEY, fcmToken);
      }
    };

    handleFcmToken();
  }, [addRecipientToken, fcmToken]);

  return (
    <>
      {renderNotificationPopup()}
      {children}
      {/* If you need to debug the token, click the footer 20 times to show registered token */}
      {counter === 20 && window.ENVIRONMENT !== 'PRD' ? (
        <>
          <div style={{ wordBreak: 'break-all' }}>
            {`FCM Token: ${fcmToken}`}
          </div>
          <div style={{ wordBreak: 'break-all' }}>
            {`Stored FCM Token: ${localStorage.getItem(FCM_STORAGE_KEY)}`}
          </div>
        </>
      ) : null}
      <StyledGhostComponent onClick={handleClickGhostComponent} />
    </>
  );
}

export default NotificationProvider;

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
`;

const StyledPopup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  margin: ${({ isDesktop }) => (isDesktop ? '1rem' : '12px')};
  background-color: white;
  padding: 1rem;
  border-radius: 1.25rem;
  box-shadow: 0px 2px 20px 0px rgba(0, 61, 166, 0.1);
  border: 1px solid #e0e4eb;
  width: ${({ isDesktop }) => (isDesktop ? '30%' : '')};
`;

const StyledFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: 1.25rem;
`;

const StyledText = styled.p`
  font-size: 1rem;
  white-space: pre-wrap;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1.25rem;
`;

const StyledGhostComponent = styled.div`
  width: 100%;
  height: 24px;
`;

import { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/messaging'; // Import Firebase messaging
import { FCM_STORAGE_KEY } from '~/Configurations/constants';

const getFcmToken = () => localStorage.getItem(FCM_STORAGE_KEY);
const removeFcmToken = () => localStorage.removeItem(FCM_STORAGE_KEY);

const useFcmToken = ({ isLogin }) => {
  const [token, setToken] = useState('');
  const storedFcmToken = getFcmToken();
  const [notificationPermissionStatus, setNotificationPermissionStatus] = useState('');

  const clearToken = useCallback(() => {
    removeFcmToken();
  }, []);

  const retrieveToken = useCallback(async () => {
    try {
      if (
        typeof window !== 'undefined'
        && 'serviceWorker' in navigator
      ) {
        const isSupported = firebase.messaging.isSupported(); // Check if messaging is supported
        if (isSupported) {
          const messaging = firebase.messaging(); // Initialize Firebase messaging
          // Retrieve the notification permission status
          const permission = await Notification.requestPermission();
          setNotificationPermissionStatus(permission);

          // Check if permission is granted before retrieving the token
          if (permission === 'granted' && isLogin) {
            const currentToken = await messaging.getToken({
              vapidKey: window.FIREBASE_MESSAGING_VAPID_KEY,
            });
            // only return token if no FCMToken in cookies OR new token !== cookies token
            if (!storedFcmToken || storedFcmToken !== currentToken) {
              setToken(currentToken);
            }
          }
        }
      }
    } catch (error) {
      clearToken();
    }
  }, [clearToken, isLogin, storedFcmToken]);

  useEffect(() => {
    retrieveToken();
  }, [retrieveToken]);

  return {
    fcmToken: token,
    notificationPermissionStatus,
    retrieveToken,
    clearToken,
  };
};

export default useFcmToken;

/* eslint-disable max-len */

import {
  AR_AGING_STATUSES,
  BTMS_FEE_TYPES,
  COMPANY_STATUSES,
  CSV_SEPERATORS,
  CUSTOMER_DIVISION,
  CUSTOMIZABLE_INVOICE_STATUSES,
  CUSTOM_INVOICE_FEE_STATUSES,
  CUSTOM_INVOICE_FEE_TYPES,
  CUSTOM_INVOICE_FEE_UNITS,
  CUSTOM_SHIPMENT_PRICING_TYPES,
  DOCUMENT_TYPES,
  DOF2_INVOICE_REJECTION_REASON,
  DOF2_REQUEST_ON_HOLD_REASON,
  DOF_REQUEST_ON_HOLD_REASON,
  DOF_REQUEST_REJECTION_REASON,
  DOF_SHIPMENT_STATUSES,
  DO_CANCELLATION_REASONS,
  DO_REJECTION_REASONS,
  DRIVER_QUEUES_STATUS,
  DRIVER_STATUSES,
  FEE_BANNER_STATUSES,
  FEE_REQUESTS,
  FEE_TYPES,
  FEE_TYPE_STATUSES,
  FILE_UPLOAD_SOURCE,
  FILE_UPLOAD_MODE,
  FIRST_MILE_USER_ACCESS_TYPE,
  GENERAL_ENTITY_STATUSES,
  GOODS_REJECTION_CATEGORY,
  INDUSTRY,
  INVOICE_PAYMENT_STATUS,
  INVOICE_STATUS,
  IS_RETURN_FEE_COVERED,
  IS_RETURN_GOODS,
  JOB_STATUSES,
  LAST_DISBURSEMENT_PPH23_DEDUCTOR,
  LOCAL_SHIPMENT_STATUSES,
  LOCATION_CHANGE_REASON,
  LOCATION_FEE_TYPE,
  LOCATION_TYPE,
  PANTHERA_PIC_DIVISION,
  PAYMENT_SUMMARIES_SECTION_STATUSES,
  PAYMENT_SUMMARIES_STATUSES,
  PAYMENT_TYPES,
  PLATE_COLORS,
  PLATE_NUMBER_HISTORIES_COLUMN,
  PODF_INVOICE_REJECTION_REASON,
  PODF_REQUEST_ON_HOLD_REASON,
  PPH23_DEDUCTOR,
  PRODUCT_CATEGORY,
  REIMBURSEMENT_REQUIREMENT_TYPE,
  ROUTE_TYPES,
  SHIPMENT_ACCEPTANCE_STATUS,
  SHIPMENT_EXPENSE_TYPES,
  SHIPMENT_INVOICE_TYPE,
  SHIPMENT_POINT_TYPES,
  SHIPMENT_STATUSES,
  SHIPMENT_TIME_ACCURACY_STATUS,
  SHIPPER_STATUSES,
  SHIPPER_TYPES,
  SHOW_CLAIMS_IN,
  SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE,
  SWIFT_PAYMENT_REQUEST_STATUSES,
  SWIFT_PAYMENT_TYPES,
  TRANSPORTER_INCENTIVE_AMOUNT_TYPE,
  TRANSPORTER_INCENTIVE_BUSINESS_LINE,
  USER_ACCESS_TYPE,
  USER_STATUSES,
  VEHICLE_AVAILABILITY_STATUS,
  VEHICLE_REPORT_TYPE,
  VENDOR_ASSIGNMENT_TYPE,
  BIDDING_HISTORY_STATUSES,
  COACH_MARK_CONTENT,
  EXPEDITION_PROVIDER,
  CANCELLATION_INVOICE_REASON,
  ONHOLD_INVOICE_REASON,
  FLEET_MONITORING_STATUS,
  GPS_STATUS,
  READINESS_SHIPMENT_STATUS,
  MONTH_INVOICE_FORMAT,
  YEAR_INVOICE_FORMAT,
  INVOICE_FORMAT_DEFAULT,
  COMPANY_PBI_VEHICLE_STATUS,
  PPN_TYPE,
  CURRENCY_CODES,
} from '~/Configurations/constants';
import { INVOICE_FORM_TYPE, SHIPMENT_SEARCH_TYPES } from '~/Modules/Payment/Invoice/Pages/InvoiceGenericCreationPage/constants';
import { DISBURSEMENT_STATUS_ENUM } from '~/Modules/Payment/Invoice/Pages/InvoiceListPage/constant';
import { DELIVERY_ORDER_SEARCH_TYPES } from '~/Modules/Payment/constants';
import { CUSTOM_FIELDS } from '~/Modules/Shipment/Utilities/constants';

export default {
  accept_bid: 'Accept Now',
  acceptance_date: 'Acceptance Date',
  account_details: 'Account Details',
  account_holder_name: 'Account Holder Name',
  account_name: 'Account Name',
  account_number: 'Account Number',
  account_receivable: 'Account Receivable',
  account_receiveble: 'Trade Receivables',
  action: 'Action',
  activate_driver: 'Activate Driver',
  activate_truck: 'Activate Unit',
  active_kir: 'Active KIR',
  active_lembar_pajak: 'Active Tax Form',
  active_sim: 'Active Driver License',
  active_stnk: 'Active Vehicle Registration',
  active: 'Active',
  activity_log: 'Activity Log',
  activity_log_type: {
    comment: {
      UPDATE: '{{name}} left a comment',
    },
    create_company_vehicles: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    save_brokerage_vendor: {
      INSERT: 'Transporter created by {{name}}',
      UPDATE: 'Basic Profile edited by {{name}}',
    },
    save_customer: {
      INSERT: '{{name}} created a shipper',
      UPDATE: '{{name}} saved a shipper',
    },
    save_warehouse: {
      INSERT: '{{name}} created a location',
      UPDATE: '{{name}} saved a location',
    },
    save_warehouse_fee: {
      INSERT: '{{name}} created a location fee',
      UPDATE: '{{name}} saved a location fee',
    },
    update_partner_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    upsert_partner_company_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    verify_company_driver: {
      UPDATE: 'Driver verified by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    verify_vendor: {
      UPDATE: 'Transporter verified by {{name}}',
    },
  },
  actual_arrival: 'Actual Arrival Time',
  actual_cbm: 'Actual CBM Amount',
  actual_kg: 'Actual Kg Amount',
  actual_route: 'Actual Route',
  actual_utilization: 'Actual Utilization',
  add_account: 'Add Account',
  add_adjustment_details: 'Add Adjustment Details',
  add_attachment: 'Add Attachment',
  add_bank_account: 'Add Bank Account',
  add_claims: '+ Add Claims',
  add_column: 'Add Column',
  add_company_document: 'Add Company Document',
  add_component: 'Add Component',
  add_contact: '+ Add Contact',
  add_contract_document: 'Add Contract Document',
  add_costs: 'Add Costs',
  add_customer: 'Add Customer',
  add_days: 'Add Days',
  add_details: 'Add Details',
  add_do: 'Add DO',
  add_document: 'Add Document',
  add_document_resturn_sla: 'Add Document Return SLA',
  add_employee: 'Add Employee',
  add_expenses: 'Add Expenses',
  add_fee: 'Add Fee',
  add_fee_for_truck_type: 'Add Additional Fee for {{name}}',
  add_file: 'Add File',
  add_income: 'Add Income',
  add_invoice: 'Add Invoice',
  add_kargo_fee_adjustment: 'Add Cargo Fee Adjustment Details',
  add_location: 'Add Location',
  add_logo: 'Add Logo',
  add_pic_contact: 'Add PIC Contact',
  add_pod: 'Add POD',
  add_pph23: 'Add PPh23',
  add_row: 'Add Row',
  add_template_name: 'Enter Template Name',
  add_unit: 'Add Unit',
  add_warehouse: 'Add Warehouse',
  added_at: 'Added at',
  added_the_driver: 'Add Driver',
  additional_fee: 'Additional Fee',
  additional_fees: 'Miscellaneous Fees',
  additional_fee_type: {
    cancellation: {
      TRUCK_ARRIVAL: 'Cancellation after truck arrives at loading location',
      TRUCK_DETAILS_CONFIRMED: 'Cancellation after truck details are confirmed',
    },
    multishipment: 'Fee for more than one shipment',
    overnight: {
      LOADING: 'Overnight fee during loading',
      UNLOADING: 'Overnight fee during unloading',
    },
  },
  additional_notes: 'Additional Notes',
  additional_transporter_note: 'Internal Notes',
  additional_transporter_notes: 'Additional Transporter Notes',
  address: 'Address',
  adjustment: 'Adjustment',
  adjustment_type: 'Adjustment Type',
  after: 'After',
  aging: 'Aging',
  aging_date_of_submission: 'Submission Date',
  aging_disbursement_date: 'Disbursement Date',
  aging_maturity: 'Aging Maturity',
  aging_shipper_payment_date: 'Shipper Payment Date',
  aging_total_amount: 'Total Amount',
  agreement_status: 'Agreement Status',
  all_customer: 'All Customers',
  alternative_doc: 'Alternative Document',
  amount: 'Amount',
  amount_in_words: 'Amount in Words',
  applied_dof2_invoice_details: 'Applied DOF Invoice Details',
  applied_dof_shipment_list_details: 'Applied DOF Shipment List Details',
  applied_podf_invoice_details: 'Applied PODF Invoice Details',
  apply_swift_payment: 'Apply for Swift Payment',
  applying_for_dof_2nd_disbursement: 'Applying for DOF Second Disbursement',
  applying_for_podf: 'Applying for PODF',
  applying_for_swift_payment: 'Applying for Swift Payment',
  apply: 'Apply',
  approved_dof2_invoice_details: 'Approved DOF Invoice Details',
  approved_dof_shipment_list_details: 'Approved DOF Shipment List Details',
  approved_podf_invoice_details: 'Approved PODF Invoice Details',
  ar_aging_status_map: {
    [AR_AGING_STATUSES.DISBURSED]: 'First disbursement transferred',
    [AR_AGING_STATUSES.ON_HOLD]: 'Submission on Hold',
    [AR_AGING_STATUSES.REQUESTED]: 'Submitted',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_REQUESTED]: 'Second disbursement requested',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_APPROVED]: 'Second disbursement processed',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'Second disbursement in process',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'Second disbursement transferred',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_REJECTED]: 'Second disbursement rejected',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_ON_HOLD]: 'Second disbursement on hold',
    [AR_AGING_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'Third disbursement in process',
    [AR_AGING_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'Third disbursement transferred',
  },
  armada_info: 'Fleet Information',
  arrive_at_location: 'Arrive at Location',
  ascending: 'Ascending',
  attachment: 'Attachment',
  availability_info: 'Availability Information',
  available_slot: 'Available Slot',
  availablity_schedule: 'Availability Schedule',
  availability_status: 'Availability Status',
  average_load_time: 'Average Load Time',
  average_unload_time: 'Average Unload Time',
  average_vehicle_load_time: 'Average Load Time - All Vehicle Types',
  average_vehicle_unload_time: 'Average Unload Time - All Vehicle Types',
  back: 'Back',
  bank: 'Bank',
  bank_account: 'Bank Account',
  bank_account_number: 'Account Number',
  bank_account_owner: 'Account Owner',
  bank_code: 'Bank Code',
  bank_name: 'Bank Name',
  bank_information: 'Bank Information',
  bank_information_is: 'Bank Information',
  before: 'Before',
  bid: 'Bid',
  bid_accepted: 'Accepted',
  bid_deadline: 'Bid Deadline',
  bid_faq: 'How does bidding work?',
  bid_maximum_price: 'Maximum bid is {{value}}',
  bid_minimum_price: 'Minimum bid is {{value}}',
  bid_maximum_slot: 'Maximum number is {{value}} Slot',
  bid_price: 'Bid Price',
  bidding: 'Bidding',
  bidding_active: 'Your Bid',
  bidding_available: 'Available Bids',
  bidding_price: 'Bidding Price',
  bidding_statuses_map: {
    [BIDDING_HISTORY_STATUSES.ACCEPTED]: 'Accepted',
    [BIDDING_HISTORY_STATUSES.CANCELLED_BY_SYSTEM]: 'Rejected',
    [BIDDING_HISTORY_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [BIDDING_HISTORY_STATUSES.CANCELLED_AT_CONFIRMATION]: 'Cancelled by Vendor',
    [BIDDING_HISTORY_STATUSES.CANCELLED_BY_SHIPPER]: 'Customer Cancelled',
    [BIDDING_HISTORY_STATUSES.CANCELLED_BY_INTERNAL]: 'Customer Reassigned',
    [BIDDING_HISTORY_STATUSES.REJECTED]: 'Rejected',
    [BIDDING_HISTORY_STATUSES.UNFULFILLED]: 'Confirmation Missed',
  },
  bidding_incentive: 'Incentive ({{percent}}%)',
  bidding_total: 'Total Bids',
  billing_address: 'Billing Address',
  billing_transfer_account: 'Billing Transfer Account',
  birth_date: 'Birth Date',
  body_code: 'Body Code',
  brand_name: 'Brand Name',
  btms_upload_document: {
    add_do_number: 'Add Delivery Order Number',
    add_shipment_quantities: 'Add Delivered Goods',
    claim_included: 'Includes Claim',
    no_claim_included: 'No Claim Included',
  },
  bulk_upload_csv: {
    cancel: 'Cancel',
    csv_separator: 'CSV Separator',
    csv_upload_shipment: 'Upload Shipment from CSV file',
  },
  bulk_upload_excel: {
    cancel: 'Cancel',
    multiple_pickup_dropoff: 'Multiple Pickup/Dropoff',
    single_pickup_dropoff: 'Single Pickup/Dropoff',
    upload_shipment: 'Upload Shipment',
    upload_shipment_type_map: {
      [FILE_UPLOAD_MODE.SINGLE_PICKUP_DROPOFF]: 'Upload Single Pickup/Dropoff Shipment',
      [FILE_UPLOAD_MODE.MULTIPLE_PICKUP_DROPOFF]: 'Upload Multiple Pickup/Dropoff Shipment',
    },
  },
  calendar_days: 'Calendar Days',
  cancel: 'Cancel',
  cancel_it: 'Cancel It',
  cancel_bid: 'Cancel Bid',
  cancel_invoice: 'Cancel Invoice',
  cancel_invoice_alert: 'This invoice will be canceled and cannot be reactivated',
  cancel_reason: 'Cancellation Reason',
  cancel_shipment: 'Cancel Shipment',
  cancellation_fee: 'Cancellation Fee',
  cancellation_fees: 'Cancellation Fees',
  cancellation_invoice_reason: {
    [CANCELLATION_INVOICE_REASON.NEED_REVISION]: 'Invoice details need revision',
    [CANCELLATION_INVOICE_REASON.INCOMPLETE]: 'Attachments are incomplete',
    [CANCELLATION_INVOICE_REASON.OTHER_REASON]: 'Other Reasons',
  },
  cancellation_reason: 'Cancellation Reason',
  cancelled_by_internal: 'Customer Reassigned',
  cbm: 'CBM',
  cbm_actual: 'Actual CBM',
  cbm_expected: 'Expected CBM',
  central_address: 'Central Address',
  certain_products: 'Description',
  change: 'Change',
  change_adjustment_details: 'Edit Adjustment Details',
  change_customer: 'Edit Customer',
  change_details: 'Edit Details',
  change_invoice: 'Edit Invoice',
  change_invoice_status: 'Edit Invoice Status',
  change_password: 'Change Password',
  change_shipment: 'Edit Shipment',
  change_slot: 'Change Slot',
  change_status: 'Change Status',
  change_truck_and_driver: 'Change Truck & Driver',
  change_warehouse: 'Edit Warehouse',
  changes: 'Changes',
  changesInCustomerPricesClaimTransferFee: 'Changes in Customer Prices - Claim - Transfer Fee',
  chassis_number: 'Chassis Number',
  check_account: 'Check Account',
  choose_all: 'Select All',
  choose_bank: 'Select Bank',
  choose_company: 'Select Company',
  choose_customer: 'Select Customer',
  choose_document_to: 'Select document source to view',
  choose_driver: 'Select Driver',
  choose_file: 'Select File',
  choose_language: 'Select Language',
  choose_pod: 'Select POD',
  choose_shipment: 'Select Shipment',
  choose_shipment_number: 'Select Shipment Number',
  choose_shipment_from_dof: 'Select Shipment from DOF',
  choose_truck: 'Select Truck',
  choose_unit: 'Select Unit',
  city: 'City',
  claim: 'Claim',
  claim_value: 'Claim Value',
  claim_value_collection_sla: 'Claim Value Collection SLA',
  claim_terms: 'Claim Terms',
  claims: 'Claims',
  clear: 'Clear',
  close: 'Close',
  closed: 'Closed',
  column_form_placeholder: 'Enter Column Title',
  company: 'Company',
  company_address: 'Company Address',
  company_code: 'Company Code',
  company_details: 'Company Details',
  company_pricing: {
    usage_status: 'Usage Status',
    history: {
      history_title: 'Usage History',
      cycle: 'Cycle',
      commitment_do: 'Commitment DO',
      created_do: 'DO Created',
      cycle_start: 'Start',
      cycle_end: 'End',
      cycle_month: 'Month',
    },
    enterprise: {
      contract_period: 'Contract Period',
      commitment_do: 'Commitment DO',
      created_do: 'DO Created',
    },
    non_enterprise: {
      kuota_detail: 'Total DO Quota Details',
      kuota_do: 'DO Quota',
      free_kuota: 'Free Quota',
      extra_kuota: 'Extra Quota',
      total_kuota: 'Total DO Quota',
      tooltip_info: 'Total DOs created from this month\'s DO quota',
    },
  },
  company_document: 'Company Document',
  company_document_type: 'Company Document Type',
  company_documents: 'Company Documents',
  company_name: 'Company Name',
  company_number_already_exists: 'Company Name already exists',
  company_settings: 'Company Settings',
  company_status_map: {
    [COMPANY_STATUSES.ACTIVE]: 'Active',
    [COMPANY_STATUSES.CHURN]: 'Churn',
    [COMPANY_STATUSES.DEACTIVATED]: 'Deactivated',
    [COMPANY_STATUSES.INACTIVE]: 'Inactive',
    [COMPANY_STATUSES.REGISTERED]: 'Registered',
  },
  company_title: 'Company',
  company_pbi_vehicle_status: {
    [COMPANY_PBI_VEHICLE_STATUS.UNAVAILABLE]: 'You cannot place a bid because you have not added the type of truck requested by the Customer',
    [COMPANY_PBI_VEHICLE_STATUS.PENDING_VERIFICATION]: 'You cannot place a bid because the unit is still under verification',
  },
  complete_now: 'Complete Now',
  completion_date: 'Completion Date',
  confirm: 'Confirm',
  confirm_fee_delete: 'Confirm fee deletion',
  confirm_proforma_invoice: 'Confirm Proforma Invoice',
  confirm_your_availability: 'Confirm your availability',
  confirmation: 'Confirmation',
  confirmation_expired: 'Confirmation Expired',
  contact: 'Contact',
  contact_phone: 'Contact Phone',
  contacts: 'Contacts',
  container: 'Container',
  container_information: 'Container Information',
  container_number: 'Container Number',
  continue: 'Continue',
  contract_document_type: 'Contract Document Type',
  contract_documents: 'Contract Documents',
  count_included: 'Counted',
  create: 'Create',
  create_component: 'Create Component',
  create_do_block_dialog_text: 'Contact Kargo Nexus for Transporters to increase your DO quota',
  create_do_block_dialog_title: 'DO Quota Has Reached Maximum Limit',
  create_do_block_grace_period_dialog_description: 'You cannot access this feature because your contract period has expired. Contact us to stay connected with all Kargo Nexus for Transporters features',
  create_format: 'Create Format',
  create_do_block_grace_period_dialog_title: 'Contact us to use this feature',
  create_invoice: 'Create Invoice',
  create_invoice_revision: 'Create Invoice Revision',
  create_invoice_type: {
    [SHIPMENT_INVOICE_TYPE.ONCALL]: 'Create On Call Invoice',
    [SHIPMENT_INVOICE_TYPE.RENTAL]: 'Create Dedicated Invoice',
  },
  create_new_customer: 'Create New Customer',
  create_new_invoice: 'Create New Invoice',
  create_new_shipment: 'Create New Shipment',
  create_new_template: 'Create New Template',
  create_new_shipment_template: 'Create New Shipment Template',
  create_new_warehouse: 'Create New Warehouse',
  create_proforma_invoice: 'Create Proforma Invoice',
  create_shipment: 'Create Shipment',
  csv_separators: {
    [CSV_SEPERATORS.COMMA]: 'Comma ( , )',
    [CSV_SEPERATORS.COLON]: 'Colon ( : )',
    [CSV_SEPERATORS.SEMICOLON]: 'Semicolon ( ; )',
    [CSV_SEPERATORS.PIPE]: 'Pipe ( | )',
    [CSV_SEPERATORS.TAB]: 'Tab ( \\t )',
  },
  currency_prefix_map: {
    [CURRENCY_CODES.IDR]: 'Rp',
    [CURRENCY_CODES.MYR]: 'RM',
    [CURRENCY_CODES.SGD]: '$',
    [CURRENCY_CODES.USD]: '$',
  },
  customer_code: 'Customer Code',
  custom_fields: {
    add_field: 'Add Other Input',
    custom_field_dialog: {
      dialog_header: 'Select Additional Info Type Outside Shipment',
      dialog_cancel: 'Cancel',
      dialog_ok: 'Save',
      other_input_name_label: 'Input Label Name',
      other_input_name_placeholder: 'Enter Label Name (Max. 50 Characters)',
    },
    custom_field_radio_label: {
      [CUSTOM_FIELDS.EXTERNAL_ID]: 'External ID',
      [CUSTOM_FIELDS.CUSTOMER_ORDER_DATE]: 'Customer Order Date',
      [CUSTOM_FIELDS.SHIPMENT_TYPE]: 'Shipment Type',
      [CUSTOM_FIELDS.INTERNAL_NOTES]: 'Internal Notes',
      [CUSTOM_FIELDS.OTHER]: 'Others',
      [CUSTOM_FIELDS.CONTAINER_NUMBER]: 'Container Number',
      [CUSTOM_FIELDS.SHIPPER_SEAL_NUMBER]: 'Shipper Seal Number',
      [CUSTOM_FIELDS.CUSTOMER_SEAL_NUMBER]: 'Customer Seal Number',
      [CUSTOM_FIELDS.CHASSIS_NUMBER]: 'Chassis Number',
    },
  },
  custom_invoice_fee_form_map: {
    amount: {
      label: 'Fee Amount',
      placeholder: 'Type fee amount',
    },
    fee_type: {
      label: 'Fee Type',
      placeholder: '',
    },
    notes: {
      label: 'Notes',
      placeholder: 'Type additional information related to the fee',
    },
    PER_CBM: {
      label: 'CBM Volume (Optional)',
      placeholder: 'Enter volume in cubic meters',
    },
    PER_KG: {
      label: 'Kilograms (Optional)',
      placeholder: 'Enter cargo weight in kg\'s',
    },
    total_amount: {
      label: 'Total Fee',
      placeholder: '',
    },
  },
  custom_invoice_fee_status: {
    [CUSTOM_INVOICE_FEE_STATUSES.APPROVED]: 'Approved',
    [CUSTOM_INVOICE_FEE_STATUSES.NOT_INVOICED]: 'Not Invoiced',
    [CUSTOM_INVOICE_FEE_STATUSES.PAID]: 'Paid',
    [CUSTOM_INVOICE_FEE_STATUSES.PAYMENT_PENDING]: 'Invoice Sent',
    [CUSTOM_INVOICE_FEE_STATUSES.REJECTED]: 'Rejected',
  },
  custom_invoice_fee_types: {
    [CUSTOM_INVOICE_FEE_TYPES.LOADING_FEE]: 'Loading Fee',
    [CUSTOM_INVOICE_FEE_TYPES.MULTIDROPOFF_FEE]: 'Multidrop Fee',
    [CUSTOM_INVOICE_FEE_TYPES.MULTIPICKUP_FEE]: 'Multipickup Fee',
    [CUSTOM_INVOICE_FEE_TYPES.OTHER_FEE]: 'Other Fee',
    [CUSTOM_INVOICE_FEE_TYPES.OVERNIGHT_FEE]: 'Overnight Fee',
    [CUSTOM_INVOICE_FEE_TYPES.SHIPMENT_FEE]: 'Shipment Fee',
    [CUSTOM_INVOICE_FEE_TYPES.TICKET_FEE]: 'Ticket Fee',
    [CUSTOM_INVOICE_FEE_TYPES.TRUCKING_FEE]: 'Trucking Fee',
    [CUSTOM_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'Unloading Fee',
    [CUSTOM_INVOICE_FEE_TYPES.ADDITIONAL]: 'Additional Fee',
    [CUSTOM_INVOICE_FEE_TYPES.OTHER_LOCATION_FEE]: 'Other Location Fee',
    [CUSTOM_INVOICE_FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'Overnight Loading Fee',
    [CUSTOM_INVOICE_FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'Overnight Unloading Fee',

    // Will refactor this on the next sprint
    [CUSTOM_INVOICE_FEE_TYPES.shipment]: 'Shipment Fee',
    [CUSTOM_INVOICE_FEE_TYPES.additional]: 'Additional Fee',
    [CUSTOM_INVOICE_FEE_TYPES.unloading]: 'Unloading Fee',
    [CUSTOM_INVOICE_FEE_TYPES.loading]: 'Loading Fee',
    [CUSTOM_INVOICE_FEE_TYPES.other]: 'Other Fee',
    [CUSTOM_INVOICE_FEE_TYPES.overnight_unloading]: 'Overnight Unloading Fee',
    [CUSTOM_INVOICE_FEE_TYPES.overnight_loading]: 'Overnight Loading Fee',

    // First Mile Fees
    [CUSTOM_INVOICE_FEE_TYPES.storage]: 'Storage Fee',
    [CUSTOM_INVOICE_FEE_TYPES.demurrage]: 'Demurrage Fee',
    [CUSTOM_INVOICE_FEE_TYPES.thc_lolo]: 'THC-LOLO',
  },
  custom_invoice_fee_units: {
    [CUSTOM_INVOICE_FEE_UNITS.PER_CBM]: 'Per CBM',
    [CUSTOM_INVOICE_FEE_UNITS.PER_KG]: 'Per Kg',
    [CUSTOM_INVOICE_FEE_UNITS.PER_ROUTE]: 'Per Route',
  },
  custom_invoice_form_creation_headers: {
    bank_details: 'Invoice Payment Destination',
    billing_date: 'Billing Date',
    other_details: 'Other Details',
    total: 'Total Invoice',
    total_summary: 'Invoice Summary',
  },
  custom_invoice_toast: {
    fee_create: 'Successfully added fee',
    fee_edit: 'Successfully edited fee',
  },
  custom_shipment_fee_units: {
    [CUSTOM_SHIPMENT_PRICING_TYPES.PER_CBM]: 'Per Cbm',
    [CUSTOM_SHIPMENT_PRICING_TYPES.PER_KG]: 'Per Kg',
    [CUSTOM_SHIPMENT_PRICING_TYPES.PER_SHIPMENT]: 'Per Shipment',
  },
  customer: 'Customer',
  customer_details: 'Customer Details',
  customer_division: {
    [CUSTOMER_DIVISION.FINANCE]: 'Finance',
    [CUSTOMER_DIVISION.OPERATIONS]: 'Operations',
    [CUSTOMER_DIVISION.SALES]: 'Sales',
    [CUSTOMER_DIVISION.WAREHOUSE]: 'Warehouse',
    // Commented out for possible future use. Currently only valid for B-TMS - see constants.js
    // [CUSTOMER_DIVISION.PROCUREMENT]: 'Procurement',
    // [CUSTOMER_DIVISION.LOGISTICS]: 'Logistics',
    // [CUSTOMER_DIVISION.TREASURY]: 'Treasury',
    // [CUSTOMER_DIVISION.CLAIMS]: 'Claims',
  },
  customer_information: 'Customer Information',
  customer_list: 'Customer List',
  customer_name: 'Customer Name',
  customer_name_slash_company: 'Customer Name / Company',
  customer_seal_number: 'Customer Seal Number',
  customer_title: 'Customer',
  cv: 'CV',
  damage: 'Damage',
  damage_description: 'Damage Description',
  damage_type: 'Damage Type',
  date: 'Date',
  date_of_submission: 'Date of Submission',
  day: 'Day',
  days: 'Days',
  deactivate: 'Deactivate',
  deactivate_customer: 'Deactivate Customer',
  deactivate_driver: 'Deactivate Driver',
  deactivate_employee: 'Deactivate Employee',
  deactivate_truck: 'Deactivate Unit',
  deactivate_warehouse: 'Deactivate Warehouse',
  deadline_confirmation: 'Confirmation Deadline',
  deadline_confirmation_tooltip_message: 'Confirm your availability as soon as possible so that the customer has more time to schedule their shipment.',
  deadline_date_and_time: 'Deadline Date And Time',
  dedicated_invoice: 'Dedicated Invoice',
  dedicated_rental_invoice: 'Dedicated/Rental Invoice',
  default_account: 'Primary Account',
  delay_submission: 'Delay Submission',
  delete: 'Delete',
  deleted: 'Deleted',
  delete_account: 'Delete Account',
  delete_fee: 'Delete Fee',
  delete_invoice: 'Delete Invoice',
  delete_sort: 'Delete Sort',
  delete_template: 'Delete Template',
  delivery_order: 'Delivery Order',
  delivery_order_search_type: {
    [SHIPMENT_SEARCH_TYPES.DO_NUMBER]: 'Shipment Number',
    [SHIPMENT_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
    [SHIPMENT_SEARCH_TYPES.LICENSE_PLATE]: 'License Plate Number',
  },
  department: 'Department',
  descending: 'Descending',
  description: 'Description',
  deselect_all: 'Deselect All',
  destination_warehouse_name: 'Destination Warehouse Name',
  destination: 'Destination',
  detail: 'Details',
  detail_of_rejection: 'Document Rejection',
  detail_title: 'Details',
  details_example: 'Example: “Reference No.”, “PO No.”, “attn”, etc.',
  disbursement_date: 'Disbursement Date',
  disbursement_scheme: 'Disbursement Scheme',
  disbursement_summary: 'Disbursement Summary',
  disbursement_scheme_stage_map: {
    do_stage: 'DO',
    emoney_stage: 'E-Money',
    invoice_paid_stage: 'Invoice Paid',
    invoice_sent_stage: 'Invoice Sent',
    pod_digital_stage: 'Digital POD',
    pod_physical_stage: 'Physical POD',
    pol_stage: 'POL',
  },
  disbursement_status_enum_map: {
    [DISBURSEMENT_STATUS_ENUM.IN_PROCESS]: 'In Process',
    [DISBURSEMENT_STATUS_ENUM.WAITING_FOR_WITHDRAWAL]: 'Awaiting Withdrawal',
  },
  disbursed_amount: 'Disbursed Amount',
  district: 'District',
  division: 'Division',
  do: 'DO',
  do_cancellation_reason_map: {
    [DO_CANCELLATION_REASONS.ALLOCATION_REVISION]: 'Allocation revision, shipment change',
    [DO_CANCELLATION_REASONS.CANCELLED_BY_SHIPPER]: 'Customer Canceled Shipment',
    [DO_CANCELLATION_REASONS.COMPLIANCE_ISSUE]: 'Compliance issue',
    [DO_CANCELLATION_REASONS.FORCE_MAJEURE]: 'Force majeure',
    [DO_CANCELLATION_REASONS.FULL_STOCK]: 'Full stock at customer',
    [DO_CANCELLATION_REASONS.LOADING_DELAY]: 'Loading process issue at factory',
    [DO_CANCELLATION_REASONS.NO_EMPTY_JUGS]: 'No empty jugs available (for jug shipments)',
    [DO_CANCELLATION_REASONS.NO_PALLET_JUGRACK]: 'No pallet/jug rack available',
    [DO_CANCELLATION_REASONS.NO_STOCK]: 'No stock at factory/production issue',
    [DO_CANCELLATION_REASONS.OTHER_REASON]: 'Other reasons not listed above',
    [DO_CANCELLATION_REASONS.TKBM_ISSUE]: 'TKBM issue',
    [DO_CANCELLATION_REASONS.TRUCK_NO_SHOW]: 'Truck did not show up, auto expired',
    [DO_CANCELLATION_REASONS.DRIVER_ABSENCE]: 'Driver absent',
    [DO_CANCELLATION_REASONS.DRIVER_ILL]: 'Driver ill',
    [DO_CANCELLATION_REASONS.DRIVER_NOT_VACCINATED]: 'Driver not vaccinated',
    [DO_CANCELLATION_REASONS.EXPIRED_KIR]: 'KIR vehicle expired',
    [DO_CANCELLATION_REASONS.EXPIRED_SIM]: 'Driver’s license expired',
    [DO_CANCELLATION_REASONS.EXPIRED_STNK]: 'Vehicle registration expired',
    [DO_CANCELLATION_REASONS.VEHICLE_ISSUE]: 'Vehicle issue/damaged',
    [DO_CANCELLATION_REASONS.VEHICLE_TAX_NOT_PAID]: 'Vehicle tax not paid',
    [DO_CANCELLATION_REASONS.WRONG_BID_INPUT]: 'Wrong bid input',
    [DO_CANCELLATION_REASONS.CUSTOMER_ORDER_ABORTED]: 'Customer no longer wants to ship',
    [DO_CANCELLATION_REASONS.MOVED_TO_ANOTHER_PLATFORM]: 'Shipment using another app',
  },
  do_creation_date: 'DO Creation Date',
  do_date: 'DO Date',
  do_estimated_price: 'Estimated Posted Price',
  do_inactive_reason_map: {
    [DO_CANCELLATION_REASONS.ALLOCATION_REVISION]: 'Allocation revision, shipment change',
    [DO_CANCELLATION_REASONS.COMPLIANCE_ISSUE]: 'Compliance issue',
    [DO_CANCELLATION_REASONS.FORCE_MAJEURE]: 'Force majeure',
    [DO_CANCELLATION_REASONS.FULL_STOCK]: 'Full stock at customer',
    [DO_CANCELLATION_REASONS.LOADING_DELAY]: 'Loading process issue at factory',
    [DO_CANCELLATION_REASONS.NO_EMPTY_JUGS]: 'No empty jugs available (for jug shipments)',
    [DO_CANCELLATION_REASONS.NO_PALLET_JUGRACK]: 'No pallet/jug rack available',
    [DO_CANCELLATION_REASONS.NO_STOCK]: 'No stock at factory/production issue',
    [DO_CANCELLATION_REASONS.OTHER_REASON]: 'Other reasons not listed above',
    [DO_CANCELLATION_REASONS.TKBM_ISSUE]: 'TKBM issue',
    [DO_CANCELLATION_REASONS.TRUCK_NO_SHOW]: 'Truck did not show up, auto expired',
    [DO_REJECTION_REASONS.DRIVER_NOT_READY]: 'Driver not ready',
    [DO_REJECTION_REASONS.OTHER_REASON]: 'Other Reasons',
    [DO_REJECTION_REASONS.VEHICLE_MAINTENANCE_NEEDED]: 'Vehicle needs maintenance',
    [DO_REJECTION_REASONS.VEHICLE_NOT_READY]: 'Vehicle not ready',
  },
  do_margin: 'Margin',
  do_max_bid_limit: 'Max Bid Limit',
  do_number: 'DO Number',
  do_rejection_reason_map: {
    [DO_REJECTION_REASONS.DRIVER_NOT_READY]: 'Driver not ready',
    [DO_REJECTION_REASONS.OTHER_REASON]: 'Other Reasons',
    [DO_REJECTION_REASONS.VEHICLE_MAINTENANCE_NEEDED]: 'Vehicle needs maintenance',
    [DO_REJECTION_REASONS.VEHICLE_NOT_READY]: 'Vehicle not ready',
  },
  do_total: 'Total DO',
  do_type: 'DO Type',
  do_warning: 'Canceling this shipment after confirmation will affect your chances of receiving future shipments.',
  document: 'Document',
  document_expiry_date: '{{name}} Valid Until',
  document_name: 'Document Name',
  document_number: '{{name}} Number',
  document_returns: 'Document Returns',
  document_section: {
    delete_dialog_label: 'Are you sure you want to delete this document?',
    delete_dialog_title: 'Delete Document?',
    upload_date: 'Upload Date',
    upload_document_title: 'Upload Delivery Note',
  },
  document_type: 'Document Type',
  document_type_map: {
    [DOCUMENT_TYPES.CLAIMS_AND_RETURNS]: 'Claims and Returns',
    [DOCUMENT_TYPES.CLAIM_AND_RETURN_DOCUMENT]: 'Claims and Returns',
    [DOCUMENT_TYPES.CLAIM_AND_RETURN_GOODS]: 'Claims and Returns',
    [DOCUMENT_TYPES.DRIVER_REJECTED_GOODS]: 'Rejected Goods',
    [DOCUMENT_TYPES.DRIVER_REJECTION_LETTER]: 'Rejection Report',
    [DOCUMENT_TYPES.POD]: 'Unload Delivery Note',
    [DOCUMENT_TYPES.POL]: 'Load Delivery Note',
    [DOCUMENT_TYPES.OTHER_DOCUMENT]: 'Other Document',
  },
  documents: 'Documents',
  document_not_uploaded: 'Document not uploaded',
  dof: 'DOF',
  dof1_details: {
    view_pod: 'View Unload Delivery Note',
    view_pol: 'View Load Delivery Note',
  },
  dof2_invoice_detail: 'Invoice Details',
  dof2_invoice_receipt_rejection_reason_map: {
    [DOF2_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Invoice Receipt is Blurry',
    [DOF2_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'Invoice Number does not match Receipt',
    [DOF2_INVOICE_REJECTION_REASON.OTHER]: 'Others',
  },
  dof2_invoice_rejection_reason_map: {
    [PODF_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Blurry Invoice',
    [PODF_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'Invoice Number not on Invoice Receipt',
    [PODF_INVOICE_REJECTION_REASON.OTHER]: 'Others',
  },
  dof2_podf_details: {
    upload_pod: 'Upload Unload Delivery Note',
    view_pod: 'View Delivery Note',
  },
  dof2_podf_error_toast: {
    trucking_fee: 'One of the DOs does not have a Trucking Fee',
    upload_pod: 'You need to upload Unload Delivery Note to proceed',
  },
  dof2_request_on_hold_reason: {
    [DOF2_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]: 'Revise the submission based on the reasons provided to process the disbursement',
    [DOF2_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Submission exceeds credit limit',
    [DOF2_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Late payment issue',
    [DOF2_REQUEST_ON_HOLD_REASON.OTHER]: 'Others',
  },
  dof2_request_status_map: {
    [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'Second Disbursement Processed',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'Second Disbursement Transferred',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'Second Disbursement Partially Processed',
    [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'Submission On Hold',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'Rejected',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'Second Disbursement Requested',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'Third Disbursement Transferred',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'Third Disbursement In Process',
  },
  dof_2nd_disbursement: 'DOF Second Disbursement',
  dof_2nd_disbursement_summary: 'DOF Second Disbursement Summary',
  dof_actual_top: 'DOF1 Actual ToP',
  dof_at_first_disbursement: 'DOF at First Disbursement',
  dof_request_number: 'DOF1 Request Number',
  dof_request_on_hold_reason: {
    [DOF_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]: 'Revise the submission based on the reasons provided to process the disbursement',
    [DOF_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Submission exceeds credit limit',
    [DOF_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Late payment issue',
    [DOF_REQUEST_ON_HOLD_REASON.OTHER]: 'Others',
  },
  dof_request_rejection_reason: {
    [DOF_REQUEST_REJECTION_REASON.OTHER]: 'Others',
    [DOF_REQUEST_REJECTION_REASON.POD_BLUR]: 'Unload Delivery Note is blurry',
    [DOF_REQUEST_REJECTION_REASON.POD_SHIPMENT_NUMBER_MISMATCH]: 'Shipment Number in Unload Delivery Note does not match Shipment Number in Nexus for Transporters',
    [DOF_REQUEST_REJECTION_REASON.POL_BLUR]: 'Load Delivery Note is blurry',
    [DOF_REQUEST_REJECTION_REASON.POL_SHIPMENT_NUMBER_MISMATCH]: 'Shipment Number in Load Delivery Note does not match Shipment Number in Nexus for Transporters',
  },
  dof_shipment_statuses: {
    [DOF_SHIPMENT_STATUSES.POD_ACCEPTED]: 'Unload Delivery Note Accepted',
    [DOF_SHIPMENT_STATUSES.POD_REJECTED]: 'Unload Delivery Note Previously Rejected',
    [DOF_SHIPMENT_STATUSES.POL_ACCEPTED]: 'Load Delivery Note Accepted',
    [DOF_SHIPMENT_STATUSES.POL_PENDING]: 'Awaiting Load Delivery Note',
    [DOF_SHIPMENT_STATUSES.POL_REJECTED]: 'Load Delivery Note Previously Rejected',
  },
  done: 'Done',
  download: 'Download',
  download_csv: 'Download CSV',
  download_documents: 'Download Documents',
  download_documents_from_external: 'Download From Web',
  download_table: 'Download Table',
  download_tax_invoice_file: 'Download Tax Invoice',
  download_template: 'Download Template',
  dp: 'DP',
  driver: 'Driver',
  driver_app: 'Driver App',
  driver_information: 'Driver Information',
  driver_is_in_queue: 'This driver has already been allocated to several other shipments below:',
  driver_is_not_in_queue: 'This driver has not been allocated to any shipment',
  driver_name: 'Driver Name',
  driver_not_found: 'Driver not found',
  driver_notes: 'Notes for Driver',
  driver_pbi_do_not_found_link: 'Complete driver data ',
  driver_pbi_do_not_found_text: 'first to execute shipments from Pathera Biru Indonesia',
  driver_phone: 'Driver Phone',
  driver_phone_no: 'Driver Phone Number',
  driver_phone_number: 'Driver Phone',
  driver_safety_req: 'Driver Safety Requirements',
  driver_status_map: {
    [DRIVER_STATUSES.ACTIVE]: 'Active',
    [DRIVER_STATUSES.CREATED]: 'Documents Incomplete',
    [DRIVER_STATUSES.DEACTIVATED]: 'Deactivated',
    [DRIVER_STATUSES.DOCUMENT_EXPIRED]: 'Driver License Expired',
    [DRIVER_STATUSES.INACTIVE]: 'Inactive',
    [DRIVER_STATUSES.REGISTERED]: 'Registered',
    [DRIVER_STATUSES.SUSPENDED]: 'Suspended',
  },
  driver_queues_column_customer: 'Customer',
  driver_queues_column_delivery_number: 'Shipment No.',
  driver_queues_column_loading_date: 'Loading Schedule',
  driver_queues_column_queue: 'Queue',
  driver_queues_column_route: 'Route',
  driver_queues_status: {
    [DRIVER_QUEUES_STATUS.ACTIVE]: 'Active in Driver App',
  },
  driver_queues_title: 'Shipment Queue',
  driver_requirements: 'Driver Requirements',
  drivers: 'Drivers',
  dropoff: 'Drop-off',
  due_date: 'Due Date',
  edit: 'Edit',
  edit_customer: 'Edit Customer',
  edit_driver: 'Edit Driver',
  edit_employee: 'Edit Employee',
  edit_expenses: 'Edit Expenses',
  edit_fee: 'Edit Fee',
  edit_invoice: 'Edit Invoice',
  edit_invoice_receipt: 'Edit Invoice Receipt',
  edit_shipment_template: 'Edit Shipment Template',
  edit_unit: 'Edit Unit',
  edit_warehouse: 'Edit Warehouse',
  email: 'Email',
  email_proforma_invoice: 'Email Proforma Invoice',
  employee_details: 'Employee Details',
  employees: 'Employees',
  end: 'End',
  end_date: 'End Date',
  end_time: 'End Time',
  enter_additional_notes: 'Enter Notes',
  enter_bank_account: 'Enter Bank Account',
  enter_expense_amount: 'Enter Expense Amount',
  estimated: 'Estimated',
  eta: 'Estimated Time of Arrival',
  expected_cbm: 'Expected CBM',
  expected_kg: 'Expected Kg',
  expedition: 'Courier',
  expedition_placeholder: 'Select Courier',
  expedition_provider: {
    [EXPEDITION_PROVIDER.GO_SEND]: 'Go Send',
    [EXPEDITION_PROVIDER.GRAB_EXPRESS]: 'GrabExpress',
    [EXPEDITION_PROVIDER.NINJA_XPRESS]: 'Ninja Xpress',
    [EXPEDITION_PROVIDER.ANTERAJA]: 'AnterAja',
    [EXPEDITION_PROVIDER.REX_KIRIMAN_EXPRESS]: 'Rex Kiriman Express',
    [EXPEDITION_PROVIDER.LION_PARCEL]: 'Lion Parcel',
    [EXPEDITION_PROVIDER.PAXEL]: 'Paxel',
    [EXPEDITION_PROVIDER.JNE]: 'JNE',
    [EXPEDITION_PROVIDER.TIKI]: 'TIKI',
    [EXPEDITION_PROVIDER.POS_INDONESIA]: 'Pos Indonesia',
    [EXPEDITION_PROVIDER.WAHANA]: 'Wahana',
    [EXPEDITION_PROVIDER.SICEPAT]: 'SiCepat',
    [EXPEDITION_PROVIDER.SICEPAT_SIUNTUNG]: 'SiCepat SiUntung',
    [EXPEDITION_PROVIDER.JNT_EXPRESS]: 'J&T EXPRESS',
    [EXPEDITION_PROVIDER.OTHER]: 'Others',
  },
  expense_type: 'Expense Type',
  expenses: 'Expenses',
  external_id: 'External ID',
  external_id_label: 'External ID',
  fast_funding: 'Fast Funding',
  fast_payment: 'Fast Payment',
  fast_payment_request: 'Fast Payment Request',
  fee: 'Fee',
  fee_banner_subtitle_map: {
    default: 'All revenue will be updated automatically.',
    partially_disbursed: 'Awaiting confirmation from Kargo for the remaining payment.',
  },
  fee_banner_title_map: {
    [FEE_BANNER_STATUSES.CANCELLED]: 'Disbursement info automation from Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.DISBURSED]: 'Disbursement info automation from Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.REVIEW_IN_PROCESS]: 'Disbursement info automation from Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.REVIEW_SHIPMENT_FEE]: 'Disbursement info automation from Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.ON_HOLD]: 'Disbursement On Hold',
    [FEE_BANNER_STATUSES.PARTIALLY_DISBURSED]: 'Partially Disbursed',
    [FEE_BANNER_STATUSES.REJECTED]: 'Payment rejected for this shipment',
    [FEE_BANNER_STATUSES.REQUEST_APPROVED]: 'Disbursement info automation from Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.WAITING_FOR_CLAIM]: 'Disbursement On Hold due to Claim',
    [FEE_BANNER_STATUSES.WAITING_FOR_CONFIRMATION]: 'Disbursement info automation from Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.WAITING_FOR_INVOICE]: 'Invoice not sent',
    [FEE_BANNER_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'Awaiting Invoice Revision',
    [FEE_BANNER_STATUSES.WAITING_FOR_OVERNIGHT]: 'Disbursement for Overnight Fee on hold',
    [FEE_BANNER_STATUSES.WAITING_FOR_PENALTY]: 'Disbursement on hold due to penalty rules',
    [FEE_BANNER_STATUSES.WAITING_FOR_TKBM]: 'TKBM Disbursement on hold',
  },
  fee_calculation: 'Fee Calculation',
  fee_changes: 'Price Changes',
  fee_delete_message: 'Are you sure you want to delete the fee?',
  fee_description: 'Enter fee description here',
  fee_map: {
    [FEE_BANNER_STATUSES.CANCELLED]: '-',
    [FEE_BANNER_STATUSES.DISBURSED]: 'Paid',
    [FEE_BANNER_STATUSES.REVIEW_IN_PROCESS]: 'In Process - Review',
    [FEE_BANNER_STATUSES.REVIEW_SHIPMENT_FEE]: 'In Process - Review',
    [FEE_BANNER_STATUSES.ON_HOLD]: 'On Hold',
    [FEE_BANNER_STATUSES.PARTIALLY_DISBURSED]: 'Partially Paid',
    [FEE_BANNER_STATUSES.REJECTED]: 'Rejected',
    [FEE_BANNER_STATUSES.REQUEST_APPROVED]: 'In Process - Disbursement',
    [FEE_BANNER_STATUSES.WAITING_FOR_CLAIM]: 'Awaiting Claim',
    [FEE_BANNER_STATUSES.WAITING_FOR_CONFIRMATION]: 'In Process - Confirmation',
    [FEE_BANNER_STATUSES.WAITING_FOR_INVOICE]: 'Not Billed',
    [FEE_BANNER_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'Awaiting Invoice Revision',
    [FEE_BANNER_STATUSES.WAITING_FOR_OVERNIGHT]: 'Awaiting Overnight Fee',
    [FEE_BANNER_STATUSES.WAITING_FOR_PENALTY]: 'Awaiting Penalty',
    [FEE_BANNER_STATUSES.WAITING_FOR_TKBM]: 'Awaiting TKBM',
  },
  fee_name: 'Fee Name',
  fee_per_point_different_city: 'Fee per Point Different City',
  fee_per_point_within_city: 'Fee per Point Within City',
  fee_type: 'Fee Type',
  fee_type_shipper: 'Additional Fee Type',
  fee_request_status_map: {
    [FEE_TYPE_STATUSES.CANCELLED]: 'Cancelled',
    [FEE_TYPE_STATUSES.DISBURSED]: 'Paid',
    [FEE_TYPE_STATUSES.FEE_REJECTED]: 'Rejected',
    [FEE_TYPE_STATUSES.REVIEW_IN_PROCESS]: 'Review Process',
    [FEE_TYPE_STATUSES.REVIEW_SHIPMENT_FEE]: 'Review Process',
    [FEE_TYPE_STATUSES.ON_HOLD]: 'On Hold',
    [FEE_TYPE_STATUSES.PENDING]: 'Confirmation Process',
    [FEE_TYPE_STATUSES.REJECTED]: 'Rejected',
    [FEE_TYPE_STATUSES.REQUEST_APPROVED]: 'Confirmation Process',
    [FEE_TYPE_STATUSES.REQUEST_SENT]: 'Review Process',
    [FEE_TYPE_STATUSES.WAITING_FOR_CLAIM]: 'On Hold',
    [FEE_TYPE_STATUSES.WAITING_FOR_CONFIRMATION]: 'On Hold',
    [FEE_TYPE_STATUSES.WAITING_FOR_INVOICE]: 'On Hold',
    [FEE_TYPE_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'On Hold',
    [FEE_TYPE_STATUSES.WAITING_FOR_OVERNIGHT]: 'On Hold',
    [FEE_TYPE_STATUSES.WAITING_FOR_PENALTY]: 'On Hold',
    [FEE_TYPE_STATUSES.WAITING_FOR_TKBM]: 'On Hold',
  },
  fee_request_type_map: {
    [FEE_REQUESTS.ADDITIONAL]: 'Additional Fee',
    [FEE_REQUESTS.ADJUSTMENT]: 'Price Adjustment',
    [FEE_REQUESTS.CLAIMS]: 'Claims',
    [FEE_REQUESTS.INSURANCE]: 'Insurance',
    [FEE_REQUESTS.KARGO]: 'Cargo Fee',
    [FEE_REQUESTS.LATE]: 'Late Fee',
    [FEE_REQUESTS.LOADING]: 'Loading Fee',
    [FEE_REQUESTS.OTHER]: 'Others',
    [FEE_REQUESTS.OTHER_ADJUSTMENT]: 'Other Adjustments',
    [FEE_REQUESTS.OVERNIGHT_LOADING]: 'Overnight Loading Fee',
    [FEE_REQUESTS.OVERNIGHT_UNLOADING]: 'Overnight Unloading Fee',
    [FEE_REQUESTS.PENALTY]: 'Penalty',
    [FEE_REQUESTS.RETURNS]: 'Returns',
    [FEE_REQUESTS.SHIPMENT]: 'Shipment Fee',
    [FEE_REQUESTS.TICKET]: 'Ticket Fine',
    [FEE_REQUESTS.UNLOADING]: 'Unloading Fee',
  },
  fees: 'Fees',
  fees_payload_details: 'Payload Details',
  fees_required_on_truck_requirement: 'Enter at least one type of overnight fee, one type of cancellation fee, and all multiple shipment fees',
  fee_types: {
    [FEE_TYPES.SHIPMENT_FEE]: 'Shipping Fee',
    [FEE_TYPES.LOADING_FEE]: 'Loading Fee',
    [FEE_TYPES.UNLOADING_FEE]: 'Unloading Fee',
    [FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'Overnight Loading Fee',
    [FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'Overnight Unloading Fee',
    [FEE_TYPES.CANCELLATION_FEE]: 'Cancellation Fee',
    [FEE_TYPES.MULTIPICKUP_FEE]: 'Multipickup Fee',
    [FEE_TYPES.MULTIDROPOFF_FEE]: 'Multidropoff Fee',
    [FEE_TYPES.OTHER_FEE]: 'Other Fees',
    [FEE_TYPES.ADDITIONAL_FEE]: 'Additional Fees',
    [FEE_TYPES.OTHER_LOCATION_FEE]: 'Other Location Fees',
  },
  file_not_selected_yet: 'File not selected yet',
  file_upload_source_map: {
    [FILE_UPLOAD_SOURCE.CA]: 'Canvasser App',
    [FILE_UPLOAD_SOURCE.DA]: 'Partner App',
    [FILE_UPLOAD_SOURCE.TA]: 'Partner App',
    [FILE_UPLOAD_SOURCE.TMS]: 'Nexus for Transporters',
    [FILE_UPLOAD_SOURCE.NFT]: 'Nexus for Transporters',
    [FILE_UPLOAD_SOURCE.BTMS]: 'Panthera Biru Indonesia',
  },
  fill_bidding_price: 'Enter bidding price',
  fill_disbursement_details: 'Enter Disbursement Details',
  fill_truck_details: 'Enter Truck Details',
  fill_invoice_detail: 'Enter Invoice Detail',
  final_invoice: 'Final Invoice',
  final_score_top: 'Final Score & TOP',
  finance_report: 'Financial Report',
  finance: 'Finance',
  find_another_payload: 'Find another payload',
  finish_load: 'Finish Loading',
  finish_load_and_unload: 'Finish Unloading and Loading',
  finish_unload: 'Finish Unloading',
  first: 'Disbursement',
  first_disbursement: 'First Disbursement',
  first_disbursement_date: 'First Disbursement Date',
  fixed_amount: 'Fixed Amount',
  fixed: 'Fixed',
  flat_pricing_type: 'Flat',
  for_receiver_at_destination: 'For Receiver at Destination',
  forgot_password: 'Forgot Password',
  format: 'Format',
  format_reset: 'Reset Format',
  from: 'From',
  from_kargo_fee: 'from Kargo Fee',
  from_kargo_nexus_for_transporters: 'from Kargo Nexus for Transporters',
  fulfillment_type: {
    [VENDOR_ASSIGNMENT_TYPE.BROADCAST_CONTRACTED]: 'Blast',
    [VENDOR_ASSIGNMENT_TYPE.SINGLE_CONTRACTED]: 'Single',
  },
  full_address: 'Full Address',
  gdl: 'GDL',
  general_entity_status_map: {
    [GENERAL_ENTITY_STATUSES.DEACTIVATED]: 'Inactive',
    [GENERAL_ENTITY_STATUSES.REGISTERED]: 'Active',
  },
  get_incentive: 'Get incentive {{value}}%',
  good: 'Good',
  goods_capacity: 'Goods Capacity',
  goods_near_expiry_date: 'Goods Near Expiry Date',
  goods_rejection_category_map: {
    [GOODS_REJECTION_CATEGORY.CLAIM]: 'Claim',
    [GOODS_REJECTION_CATEGORY.NO_CLAIM]: 'No Claim',
    [GOODS_REJECTION_CATEGORY.REPACKING]: 'Repacking',
  },
  goods_too_few: 'Goods Too Few',
  goods_too_many: 'Goods Too Many',
  goods_type: 'Type of Goods',
  gps_status_map: {
    [GPS_STATUS.ONLINE]: 'GPS Active',
    [GPS_STATUS.OFFLINE]: 'GPS Inactive',
    [GPS_STATUS.IDLE]: 'GPS Idle',
  },
  gross_profit: 'Gross Profit',
  group_load_id: 'Group Load ID',
  group_load_number: 'Group Load Number',
  help: 'Help',
  here: 'Here',
  hide: 'Hide',
  hide_notes: 'Hide Notes',
  history: 'History',
  hold_reason: 'Reason for Hold',
  hold_request: 'Hold Request',
  hour: 'Hour',
  hours: 'Hours',
  id_card: 'ID Card',
  idr: 'Rp',
  immediately_confirm: 'Immediately Confirm',
  inactive: 'Inactive',
  info: 'Information',
  instruction: 'Instruction',
  incentives: 'Incentives',
  included: 'Included',
  include_in_calculation: 'Include in Calculation',
  include_in_calculation_options: [
    {
      label: 'Sunday',
      value: 'Sunday',
    },
    {
      label: 'National Holiday',
      value: 'National Holiday',
    },
    {
      label: 'Sunday',
      value: 'Sunday',
    },
    {
      label: 'National Holiday',
      value: 'National Holiday',
    },
  ],
  income: 'Income',
  income_detail: 'Income Details',
  income_statement: 'Income Statement',
  industry: 'Industry',
  industry_map: {
    [INDUSTRY.BROKER_TRANSPORTER]: 'Broker / Transporter',
    [INDUSTRY.COMMODITY_AND_TRADING]: 'Commodity and Trading',
    [INDUSTRY.E_COMMERCE]: 'E-Commerce',
    [INDUSTRY.FMCG]: 'FMCG',
    [INDUSTRY.LOGISTIC_COMPANY]: 'Logistics Company',
    [INDUSTRY.RAW_MATERIAL_OR_ORM]: 'Raw Material / OEM',
  },
  initial_price: 'Initial Price',
  input: 'Input',
  input_payment_info: 'Enter Payment Info',
  inserted_at: 'Created Date',
  internal_note: 'Internal Note',
  internal_note_label: 'This note can be viewed by the driver in the Kargo Partner app',
  into_to_the_pocket: 'Into Pocket',
  invoice: 'Invoice',
  invoice_addressed_to: 'Invoice addressed to',
  invoice_amount_accepted: 'Invoice Amount Accepted',
  invoice_amount_submitted: 'Invoice Amount Submitted',
  invoice_code: 'Invoice Code',
  invoice_code_new: 'New Invoice Code',
  invoice_create_success_message: 'Invoice successfully created!',
  invoice_revision_success_message: 'Invoice successfully revised!',
  invoice_date: 'Invoice Date',
  invoice_detail: 'Invoice Details',
  invoice_details: 'Invoice Details',
  invoice_email_body_placeholder: 'Type a message to the invoice recipient. Example: “Here is the invoice for shipment DO-123. Thank you for using our service.”',
  invoice_format_translation: {
    [INVOICE_FORMAT_DEFAULT['/']]: '/',
    [INVOICE_FORMAT_DEFAULT['-']]: '-',
    [INVOICE_FORMAT_DEFAULT[':']]: ':',
    [INVOICE_FORMAT_DEFAULT.MONTH_CODE]: 'Month',
    [INVOICE_FORMAT_DEFAULT.YEAR_CODE]: 'Year',
    [INVOICE_FORMAT_DEFAULT.CUSTOMER_CODE]: 'Customer Code',
    [INVOICE_FORMAT_DEFAULT.INVOICE_CODE]: 'Invoice Code',
    [INVOICE_FORMAT_DEFAULT.COMPANY_CODE]: 'Company Code',
    [MONTH_INVOICE_FORMAT.NUMBER]: 'Number (1, 2, 3, 4)',
    [MONTH_INVOICE_FORMAT.ROMAN]: 'Roman (I, II, III, IV)',
    [MONTH_INVOICE_FORMAT.LETTER]: 'Letter (Jan, Feb, Mar, Apr)',
    [YEAR_INVOICE_FORMAT.COMPLETE]: 'Full (2020, 2021, 2022)',
    [YEAR_INVOICE_FORMAT.LAST_TWO_NUMBER]: 'Last two digits (20, 21, 22)',
  },
  invoice_form_type_map: {
    [INVOICE_FORM_TYPE.CREATE]: 'Create',
    [INVOICE_FORM_TYPE.EDIT]: 'Edit',
    [INVOICE_FORM_TYPE.REVISE]: 'Revise',
  },
  invoice_no: 'Invoice No.',
  invoice_number: 'Invoice Number',
  invoice_number_format: 'Invoice Number Format',
  invoice_number_format_example: 'Example Invoice Number Format',
  invoice_payment_status_filter_map: {
    [INVOICE_PAYMENT_STATUS.PAID]: 'Invoice Paid',
    [INVOICE_PAYMENT_STATUS.CREATED]: 'Unpaid',
    [INVOICE_PAYMENT_STATUS.APPROVED]: 'Physical Ready to Ship',
    [INVOICE_PAYMENT_STATUS.REJECTED]: 'Needs Revision',
  },
  invoice_payment_status_map: {
    [INVOICE_PAYMENT_STATUS.CREATED]: 'Invoice Created',
    [INVOICE_PAYMENT_STATUS.SUBMITTED]: 'Digital Invoice Reviewed',
    [INVOICE_PAYMENT_STATUS.APPROVED]: 'Digital Invoice Approved',
    [INVOICE_PAYMENT_STATUS.REJECTED]: 'Needs Revision',
    [INVOICE_PAYMENT_STATUS.REVISION_CREATED]: 'Revision Created',
    [INVOICE_PAYMENT_STATUS.REVISION_SUBMITTED]: 'Revision Under Review',
    [INVOICE_PAYMENT_STATUS.PHYSICALLY_SENT]: 'Physical Invoice Sent',
    [INVOICE_PAYMENT_STATUS.PHYSICALLY_RECEIVED]: 'Physical Invoice Reviewed',
    [INVOICE_PAYMENT_STATUS.PHYSICALLY_APPROVED]: 'Physical Invoice Approved',
    [INVOICE_PAYMENT_STATUS.ON_HOLD]: 'Invoice On Hold',
    [INVOICE_PAYMENT_STATUS.CANCELLED]: 'Cancelled',
    [INVOICE_PAYMENT_STATUS.PAYMENT_REQUESTED]: 'Payment Processed',
    [INVOICE_PAYMENT_STATUS.PAID]: 'Paid',
  },
  invoice_receipt: 'Invoice Receipt',
  invoice_receipt_deadline: 'Invoice Receipt Deadline',
  invoice_receipt_schedule: 'Invoice Receipt Schedule',
  invoice_sent_date: 'Invoice Date',
  invoice_setup_format: 'Invoice Format Settings',
  invoice_status_map: {
    [CUSTOMIZABLE_INVOICE_STATUSES.NOT_INVOICED]: 'Can be Invoiced',
    [CUSTOMIZABLE_INVOICE_STATUSES.PARTIALLY_INVOICED]: 'Partially Invoiced',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_CLAIM_APPROVAL]: 'Claim Under Review',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_POD]: 'POD Not Received',
    [CUSTOMIZABLE_INVOICE_STATUSES.ONGOING_SHIPMENT]: 'Ongoing Shipment',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_INVOICE_PAID]: 'Waiting for Shipper Payment',
    [CUSTOMIZABLE_INVOICE_STATUSES.EXPIRED_STNK]: 'Expired Vehicle Registration',
    [INVOICE_STATUS.NOT_INVOICED_YET]: 'Not Invoiced Yet',
    [INVOICE_STATUS.PAID]: 'Paid',
    [INVOICE_STATUS.PAYMENT_PENDING]: 'Invoicing',
    [INVOICE_PAYMENT_STATUS.APPROVED]: 'Approved',
    [INVOICE_PAYMENT_STATUS.REJECTED]: 'Rejected',
  },
  update: 'Update',
  update_stnk: 'Update Vehicle Registration',
  invoice_sucsessfuly_sent: 'Invoice successfully sent!',
  invoice_sucsessfuly_sent_to: 'Invoice {{invoiceNumber}} has been successfully sent to:',
  invoice_type: 'Invoice Type',
  invoice_type_filter: {
    [SHIPMENT_INVOICE_TYPE.ONCALL]: 'On Call',
    [SHIPMENT_INVOICE_TYPE.RENTAL]: 'Dedicated',
    [SHIPMENT_INVOICE_TYPE.PBI]: 'Panthera Biru Indonesia',
  },
  invoice_value: 'Invoice Value',
  invoices_details: 'Invoice Details',
  invoicing_attention: 'Invoicing Attention',
  is_return_fee_covered_map: {
    [IS_RETURN_FEE_COVERED.NEGOTIABLE]: 'Negotiable',
    [IS_RETURN_FEE_COVERED.NOT_COVERED]: 'Not Covered',
  },
  is_return_goods_map: {
    [IS_RETURN_GOODS.NO_RETURN]: 'No Return',
    [IS_RETURN_GOODS.RETURN_GOODS]: 'Return Goods',
  },
  invoice_schema_change_pbi: 'PBI Invoice Scheme Change',
  item: 'Item',
  item_type: 'Item Type',
  job_destination: 'Destination',
  job_origin: 'Origin',
  kargo_fee: 'Kargo Fee',
  kargo_fee_adjustment: 'Kargo Fee Adjustment',
  kargo_notes: 'Kargo Notes',
  kargo_system: 'Kargo System',
  kg: 'Kg',
  kg_actual: 'Actual Kg',
  kg_expected: 'Expected Kg',
  kir: 'KIR',
  kode_lambung: 'Hull Code',
  language: 'Language',
  language_map: {
    en: 'English',
    id: 'Indonesian',
  },
  last_disbursement_pph23_deductors: {
    [LAST_DISBURSEMENT_PPH23_DEDUCTOR.SHIPPER]: 'Shipper',
    [LAST_DISBURSEMENT_PPH23_DEDUCTOR.TRANSPORTER]: 'Transporter',
  },
  last_modified: 'Last Modified',
  last_updated_on: 'Last updated on',
  late: 'Late',
  late_fee: 'Late Fee',
  latest_pickup: 'Latest Pickup',
  latitude: 'Latitude',
  lead_time: 'Lead Time',
  leave_page: 'Leave Page',
  lembar_pajak: 'Tax Form',
  license_plate: 'License Plate',
  license_plate_history: 'License Plate History',
  license_plate_or_kode_lambung: 'License Plate or Hull Code',
  line: 'Line',
  load_and_unload: 'Load & Unload',
  load_and_unload_location: 'Loading & Unloading Location',
  load_date_and_time: 'Loading Date & Time',
  load_date: 'Loading Date',
  load_time: 'Loading Time',
  load_information: 'Loading Information',
  load_location: 'Loading Location',
  load_more: 'Load more',
  load_schedule: 'Loading Schedule',
  load: 'Load',
  load_point: 'Load {{pointName}}',
  loading_date: 'Loading Date',
  loading_info: 'Loading Information',
  local_shipment_statuses: {
    [LOCAL_SHIPMENT_STATUSES.ALL]: 'All',
    [LOCAL_SHIPMENT_STATUSES.UPCOMING]: 'Needs Confirmation',
    [LOCAL_SHIPMENT_STATUSES.PLANNED]: 'Scheduled',
    [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: 'At Warehouse',
    [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: 'In Transit',
    [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: 'At Location',
    [LOCAL_SHIPMENT_STATUSES.ONGOING]: 'Ongoing',
    [LOCAL_SHIPMENT_STATUSES.COMPLETED]: 'History',
  },
  location: 'Location',
  location_change_reason: 'Reason for Location Change',
  location_change_reason_map: {
    [LOCATION_CHANGE_REASON.CHANGE_OF_PLANS]: 'Change of Plans',
    [LOCATION_CHANGE_REASON.LOCATION_IS_FULL]: 'Location is Full',
    [LOCATION_CHANGE_REASON.OTHERS]: 'Others',
  },
  location_fee: 'Add Location Fee',
  location_fee_type_map: {
    [LOCATION_FEE_TYPE.LOADING]: 'Loading',
    [LOCATION_FEE_TYPE.OTHER]: 'Other',
    [LOCATION_FEE_TYPE.UNLOADING]: 'Unloading',
  },
  location_name: 'Location Name',
  location_type: {
    [LOCATION_TYPE.HOLDING]: 'Holding Location',
    [LOCATION_TYPE.NORMAL]: 'Normal',
    [LOCATION_TYPE.PORT]: 'Port',
    [LOCATION_TYPE.RETURN_DEPO]: 'Return Depot',
    [LOCATION_TYPE.STORE]: 'Store',
  },
  login: 'Login',
  logout: 'Logout',
  longitude: 'Longitude',
  main_income: 'Main Income',
  make_an_offer: 'Make an Offer',
  make_more_bidding_tooltip: 'Place more bids to be able to take loads without bidding',
  make_offer_no_vehicle_tooltip: 'You cannot make an offer because you have not added the truck type requested by the Customer',
  make_offer_waiting_verification_tooltip: 'You cannot make an offer yet because the unit is still in the verification process',
  manage_position_column: {
    [COACH_MARK_CONTENT.DESCRIPTION]: 'Hover over this icon, drag the column to another position to move it',
    [COACH_MARK_CONTENT.TITLE]: 'Manage Column Position',
  },
  margin: 'Margin',
  mark_all_as_read: 'Mark All as Read',
  mark_as_disbursed: 'Mark Disbursement as Transferred',
  mark_as_payment_received: 'Mark payment as received',
  marketplace_budget: 'Marketplace Budget',
  mass_confirmation: 'Mass Confirmation',
  mass_confirmation_warning: 'You can take all available shipments',
  mass_shipment_confirmation: 'Mass Shipment Confirmation',
  mass_shipment_confirmation_amount: 'Confirm {{amount}} shipments?',
  maximum: 'Maximum',
  max_bid_limit: 'Max Bid Limit',
  max_columns_message: 'You cannot have more than 10 columns',
  max_file_uploaded_is: 'The maximum number of files that can be uploaded is {{amount}}. Please check again',
  max_reimbursable_amount: 'Maximum reimbursable amount',
  max_transporter_bid_limit: 'Max Transporter Bid Limit',
  maximum_amount: 'Maximum amount',
  measurement_unit: 'Measurement Unit',
  message: 'Message',
  mileage: 'Mileage',
  minimum: 'Minimum',
  min_quantity_to_be_paid: 'Min Quantity to be Paid',
  min_shipper_price: 'Minimum Shipper Price',
  minimum_hours: 'Minimum Hours',
  minimum_quantity: 'Minimum Quantity',
  missing_goods: 'Missing Goods',
  mobile_no: 'Mobile Number',
  month: 'Month',
  month_setup: 'Month Setup',
  npwp: 'NPWP',
  name: 'Name',
  need_confirmation: 'Needs Confirmation',
  negotiation_price: 'Negotiation Price',
  new: 'New',
  new_backdated_shipment: 'Old Shipment',
  new_customer: 'New Customer',
  new_driver: 'New Driver',
  new_location: 'New Location',
  new_password: 'New Password',
  new_shipment: 'New Shipment',
  new_unit: 'New Unit',
  new_warehouse: 'New Warehouse',
  next: 'Next',
  next_location: 'Next Location',
  next_step: 'Next Step',
  nexus_container_number: 'Container Number',
  nft: {
    active: 'Active',
    non_active: 'Inactive',
    grace_period: 'Grace Period',
    suspended: 'Suspended',
  },
  no: 'No',
  no_cancel_it: 'No, Cancel',
  no_data_during_this_time_frame: 'No data',
  no_of_submission: 'No. of Submission',
  no_po: 'No. PO',
  nominal: 'Nominal',
  non_actual_route: 'Non-Actual Route',
  not_added_truck: 'Not Added',
  not_registered: 'Not Registered',
  not_uploaded: 'Not Uploaded',
  notes: 'Notes',
  notes_for_driver: 'Notes for Driver',
  notes_transporter: 'Transporter Notes',
  notification: 'Notification',
  now_location: 'Current Location',
  npwp_number: 'NPWP Number',
  number_of_customer_transfer: 'Number of Customer Transfers',
  number_of_shipment: 'Number of Shipments',
  office_address: 'Office Address',
  ok: 'Ok',
  ok_caps: 'OK',
  on_call_invoice: 'On Call Invoice',
  onhold_invoice_reason: {
    [ONHOLD_INVOICE_REASON.WAITING_FOR_OVERNIGHT]: 'Waiting for Overnight Fee.',
    [ONHOLD_INVOICE_REASON.WAITING_FOR_CLAIM]: 'Waiting for Claim.',
    [ONHOLD_INVOICE_REASON.WAITING_FOR_DOCUMENT]: 'Waiting for Document.',
    [ONHOLD_INVOICE_REASON.CHECKING_PROCESS]: 'Checking Process.',
    [ONHOLD_INVOICE_REASON.OTHER]: 'Other',
  },
  onboarding_date: 'Onboarding Date',
  ongoing_shipment: 'Ongoing Shipment',
  operational_hours: 'Operational Hours',
  optional: 'Optional',
  order: 'Order',
  order_details: 'Order Details',
  order_number: 'Order Number',
  order_type: 'Order Type',
  origin_warehouse_name: 'Origin',
  origin: 'Origin',
  other: 'Other',
  other_document: 'Other Document',
  other_fee: 'Other Fee',
  other_fees: 'Other Fees',
  other_income: 'Other Income',
  other_notes: 'Other Notes',
  overnight_fees_labels: {
    include_holiday: 'Include Holiday',
    include_sunday: 'Include Sunday',
    minimum_hours: 'Minimum Hours',
    overnight_fee: 'Overnight Fee',
    overnight_fees: 'Overnight Fees',
    remarks: 'Remarks',
    required_documents: 'Required Documents',
  },
  overnight_loading_info: 'Overnight Loading Information',
  overnight_unloading_info: 'Overnight Unloading Information',
  overnight_proof_description: {
    DRIVER_APP_GPS_TIMESTAMPS: '',
    FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'Not included in POD',
    OTHER: '',
    SHIPPER_APPROVAL: '',
    WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'Written and stamped in POD',
  },
  overnight_proof_type: {
    DRIVER_APP_GPS_TIMESTAMPS: 'Driver App GPS Timestamps',
    FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'Form, Event Report, or Proof of Overnight Stay',
    OTHER: 'Other',
    SHIPPER_APPROVAL: 'Shipper Approval',
    WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'Warehouse Entry and Exit Timestamps',
  },
  owner_email: 'Owner Email',
  owner_name: 'Owner Name',
  packaging_damage: 'Packaging Damage',
  pagination: {
    first: 'First',
    many: 'Many',
    next: 'Next',
    page: 'page',
    prev: 'Prev',
  },
  panthera_do_number: 'Panthera DO Number',
  panthera_poc: 'Panthera POC',
  panthera_system_disbursement: 'Disbursement from Panthera Biru Indonesia system',
  pod_pol_number: 'Delivery Note Number',
  password: 'Password',
  past_seven_days: 'Past 7 Days',
  past_three_months: 'Past 3 Months',
  payload_details: 'Payload Details',
  payment_and_tax_details: 'Payment & Tax Details',
  payment_date: 'Payment Date',
  payment_details: 'Payment Details',
  payment_info: 'Payment Info',
  payment_information: 'Payment Information',
  payment_item: 'Payment Item',
  payment_method: 'Payment Method',
  payment: 'Payment',
  payments: 'Payments',
  payment_request_status_map: {
    APPROVED: 'Disbursement Process',
    PENDING: 'Confirmation Process',
    DISBURSEMENT_FAILED: 'Disbursement Process',
    ON_HOLD: 'On Hold',
    PROCESSING: 'Disbursement Process',
    REVIEWED: 'Review Process',
    FEE_REJECTED: 'Rejected',
    REQUEST_SENT: 'Review Process',
    CANCELLED: 'Cancelled',
    REQUEST_APPROVED: 'Disbursement Process',
    DISBURSED: 'Paid',
    DELETED: 'Deleted',
  },
  payment_status: 'Payment Status',
  // Do not sort (large objects is set at the bottom to make it easier to read)
  payment_shipment_tab: {
    amount_claimed: 'Claim Amount',
    customer: 'Customer',
    inserted_at: 'Created Date',
    license_plate: 'License Plate',
    loading_date: 'Loading Date',
    shipment_number: 'Shipment Number',
    status: 'Status',
    status_last_update: 'Status Last Update Date',
    total_fees: 'Total Fees',
    additional_notes: 'Additional Notes',
    claim_fee: 'Claim Fee',
    claim_notes: 'Claim Notes',
    claim_type: 'Claim Type',
    claim_types_map: {
      'Missing Goods': 'Missing Goods',
      Damage: 'Damaged Goods',
      'Packaging Damage': 'Damaged Packaging',
      'Goods Near Expiry Date': 'Goods Rejected Due to Near Expiry Date',
      'Shipper\'s Fault - Wrong Goods': 'Wrong Goods',
      'Shipper\'s Fault - Goods Too Many': 'Goods Mismatch - Excess Goods',
      'Goods Too Few': 'Goods Mismatch - Deficient Goods',
    },
    customizable_invoice_status_map: {
      [PAYMENT_SUMMARIES_SECTION_STATUSES.DISBURSED]: 'Paid',
      [PAYMENT_SUMMARIES_SECTION_STATUSES.NEED_INVOICED]: 'Needs to be Invoiced',
      [PAYMENT_SUMMARIES_SECTION_STATUSES.ON_HOLD]: 'On Hold',
      [PAYMENT_SUMMARIES_SECTION_STATUSES.PROCESS]: 'In Process',
      [PAYMENT_SUMMARIES_SECTION_STATUSES.NOT_DISBURSED]: 'Unpaid',
    },
    day_count: 'Day Count',
    fee_types_info_map: {
      [BTMS_FEE_TYPES.SHIPMENT]: 'Shipment Fee',
      [BTMS_FEE_TYPES.LOADING]: 'Loading Fee',
      [BTMS_FEE_TYPES.UNLOADING]: 'Unloading Fee',
      [BTMS_FEE_TYPES.OTHER]: 'Other Location Fees',
      [BTMS_FEE_TYPES.OVERNIGHT_LOADING]: 'Overnight Loading Fee',
      [BTMS_FEE_TYPES.OVERNIGHT_UNLOADING]: 'Overnight Unloading Fee',
      [BTMS_FEE_TYPES.RETURNS]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.ADDITIONAL]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.TICKET]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.CLAIMS]: 'Claim',
      [BTMS_FEE_TYPES.INSURANCE]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.PENALTY]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.ADJUSTMENT]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.OTHER_ADJUSTMENT]: 'ADJUSTMENT_FEE',
    },
    fee_types_title_map: {
      [BTMS_FEE_TYPES.SHIPMENT]: 'Waybill',
      [BTMS_FEE_TYPES.LOADING]: 'Loading Fee',
      [BTMS_FEE_TYPES.UNLOADING]: 'Unloading Fee',
      [BTMS_FEE_TYPES.OTHER]: 'Parking Fee',
      [BTMS_FEE_TYPES.OVERNIGHT_LOADING]: 'Overnight Loading Fee',
      [BTMS_FEE_TYPES.OVERNIGHT_UNLOADING]: 'Overnight Unloading Fee',
      [BTMS_FEE_TYPES.RETURNS]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.ADDITIONAL]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.TICKET]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.CLAIMS]: 'Claim',
      [BTMS_FEE_TYPES.INSURANCE]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.PENALTY]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.ADJUSTMENT]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.OTHER_ADJUSTMENT]: 'ADJUSTMENT_FEE',
    },
    max_reimbursable_amount: 'Maximum Reimbursable Amount',
    minimum_hours: 'Minimum Hours',
    parking_fee: 'Parking Fee',
    payment_summaries_status_map: {
      [PAYMENT_SUMMARIES_STATUSES.CANCELLED]: 'Cancelled',
      [PAYMENT_SUMMARIES_STATUSES.DISBURSED]: 'Paid',
      [PAYMENT_SUMMARIES_STATUSES.FULLY_INVOICED]: 'Fully Invoiced',
      [PAYMENT_SUMMARIES_STATUSES.NOT_INVOICED]: 'Not Invoiced',
      [PAYMENT_SUMMARIES_STATUSES.ONGOING_SHIPMENT]: 'Ongoing Shipment',
      [PAYMENT_SUMMARIES_STATUSES.ON_HOLD]: 'On Hold',
      [PAYMENT_SUMMARIES_STATUSES.PARTIALLY_DISBURSED]: 'Partially Paid',
      [PAYMENT_SUMMARIES_STATUSES.PARTIALLY_INVOICED]: 'Partially Invoiced',
      [PAYMENT_SUMMARIES_STATUSES.REJECTED]: 'Rejected',
      [PAYMENT_SUMMARIES_STATUSES.REQUEST_APPROVED]: 'Disbursement Process',
      [PAYMENT_SUMMARIES_STATUSES.REVIEW_SHIPMENT_FEE]: 'Confirmation Process',
      [PAYMENT_SUMMARIES_STATUSES.REVIEW_IN_PROCESS]: 'Review Process',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_CLAIM]: 'Waiting for Claim',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'Waiting for Invoice Revision',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_OVERNIGHT]: 'Waiting for Overnight Fee',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_PENALTY]: 'Waiting for Penalty',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_TKBM]: 'Waiting for TKBM',
    },
    product_name: 'Product Name',
    received_amount_at_pitstop: 'Amount Received at Location',
    received_amount_returned: 'Amount Returned',
    reimbursement_requirement: 'Reimbursement Requirement',
    reimbursement_type: 'Reimbursement Type',
    reimbursement_terms: 'Reimbursement Terms',
    truck_type: 'Truck Type',
    total_payment: 'Total Payment',
    external_id: 'External ID',
  },
  payment_type: {
    [PAYMENT_TYPES.OTHER_FEE]: 'Other Fee',
    [PAYMENT_TYPES.LOADING_FEE]: 'Loading Fee',
    [PAYMENT_TYPES.OVERNIGHT_FEE]: 'Overnight Fee',
    [PAYMENT_TYPES.SHIPMENT_FEE]: 'Trucking Fee',
    [PAYMENT_TYPES.TICKET_FEE]: 'Ticket Fee',
    [PAYMENT_TYPES.UNLOADING_FEE]: 'Unloading Fee',
  },
  percentage_of_shipper_price: '% of Shipper Price',
  penerimaan_do: 'DO Receipt',
  performance: 'Performance',
  performance_report: 'Location Performance',
  performance_report_transporter: 'Transporter Performance Report',
  ketepatan_waktu: 'Timeliness',
  personal_account: 'Personal Account',
  phone: 'Phone',
  phone_number: 'Phone Number',
  physical_invoice: 'Physical Invoice',
  pic: 'PIC',
  pic_name: 'PIC Name',
  pickup: 'Pickup',
  pickup_and_dropoff: 'Pickup and Dropoff',
  pkp_number: 'PKP Number',
  planned_loading: 'Planned Loading',
  planned_unloading: 'Planned Unloading',
  plate_color: 'Plate Color',
  plate_colors: 'Plate Colors',
  plate_color_array: {
    [PLATE_COLORS.BLACK_PLATE]: 'Black',
    [PLATE_COLORS.YELLOW_PLATE]: 'Yellow',
  },
  plate_number_histories_column: {
    [PLATE_NUMBER_HISTORIES_COLUMN.LICENSE_PLATE]: 'License Plate',
    [PLATE_NUMBER_HISTORIES_COLUMN.STATUS]: 'Status',
    [PLATE_NUMBER_HISTORIES_COLUMN.TIMESTAMP]: 'Date',
    [PLATE_NUMBER_HISTORIES_COLUMN.UPDATED_BY]: 'Updated By',
  },
  po_number: 'PO Number',
  pod_details: 'POD Details',
  pod_number: 'POD Number',
  pod_uploaded_on: 'Waybill uploaded on',
  podf: 'PODF',
  podf_actual_top: 'PF1 Actual ToP',
  podf_first_disbursement_formula: '80% Invoice + VAT - PPH - Cargo Fee - VAT',
  podf_invoice_receipt_rejection_reason_map: {
    [PODF_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Blurred Invoice Receipt',
    [PODF_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'Invoice Number Does Not Match the Receipt',
    [PODF_INVOICE_REJECTION_REASON.OTHER]: 'Other',
  },
  podf_invoice_rejection_reason_map: {
    [PODF_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Blurred Invoice',
    [PODF_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'Invoice Number Not on Invoice Receipt',
    [PODF_INVOICE_REJECTION_REASON.OTHER]: 'Other',
  },
  podf_request_number: 'PF1 Request Number',
  podf_request_on_hold_reason: {
    [PODF_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]: 'Revise submission based on the reasons provided for processing the disbursement',
    [PODF_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Application Exceeds Credit Limit',
    [PODF_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Late Payment Issue',
    [PODF_REQUEST_ON_HOLD_REASON.OTHER]: 'Other',
  },
  point_type: 'Point Type',
  pool: 'Pool',
  postcode: 'Postcode',
  pph: 'PPH',
  pph23: 'PPh 23',
  pph23_deductor: 'PPh23 Deductor',
  pph23_deductors: {
    [PPH23_DEDUCTOR.PARTNER]: 'Customer',
    [PPH23_DEDUCTOR.SELF]: 'My PT',
  },
  pph23_detail: 'PPh23 Detail',
  pph23_discount: 'PPh 23 Deduction to',
  ppn: 'VAT',
  ppn_detail: 'Add VAT Details',
  preview: 'Preview',
  preview_invoice: 'Preview Invoice',
  previous: 'Previous',
  previous_location: 'Previous Location',
  previous_month: 'Previous Month',
  pr_number: 'PR Number',
  price: 'Price',
  price_calculation: 'Price Calculation',
  price_rate_type: 'Price Rate Type',
  price_adjustment: 'Price Adjustment',
  prices: 'Prices',
  pricing: 'Pricing',
  pricing_type: 'Price Type',
  print: 'Print',
  print_english: 'Print',
  product_category: 'Product Category',
  product_category_map: {
    [PRODUCT_CATEGORY.ALCOHOLIC_BEVARAGES]: 'Alcoholic Beverages',
    [PRODUCT_CATEGORY.BAKERY]: 'Bakery',
    [PRODUCT_CATEGORY.COFFEE]: 'Coffee',
    [PRODUCT_CATEGORY.CONDIMENTS_SOUCES]: 'Condiments & Sauces',
    [PRODUCT_CATEGORY.COOKING_BAKING_INGREDIENTS]: 'Cooking & Baking Ingredients',
    [PRODUCT_CATEGORY.DAIRY]: 'Dairy Products (Cold)',
    [PRODUCT_CATEGORY.DIPS]: 'Dips & Spreads',
    [PRODUCT_CATEGORY.DRINKING_WATER]: 'Drinking Water',
    [PRODUCT_CATEGORY.FITNESS_AND_NUTRITION]: 'Fitness & Nutrition',
    [PRODUCT_CATEGORY.FMCG]: 'FMCG',
    [PRODUCT_CATEGORY.FOOD]: 'Food Gift Baskets',
    [PRODUCT_CATEGORY.FROZEN]: 'Frozen Desserts & Novelties',
    [PRODUCT_CATEGORY.FRUIT_FLAVORED_DRINKS]: 'Fruit Flavored Drinks',
    [PRODUCT_CATEGORY.FRUITS]: 'Fruits & Vegetables',
    [PRODUCT_CATEGORY.GRAINS]: 'Grains, Rice & Cereals',
    [PRODUCT_CATEGORY.JUICE]: 'Juice',
    [PRODUCT_CATEGORY.MEAT]: 'Meat, Seafood & Eggs',
    [PRODUCT_CATEGORY.MEDICINE_AND_DRUGS]: 'Medicine & Drugs',
    [PRODUCT_CATEGORY.MILK]: 'Milk',
    [PRODUCT_CATEGORY.NON_DAIRY]: 'Non-Dairy Milk',
    [PRODUCT_CATEGORY.NUTS]: 'Nuts & Seeds',
    [PRODUCT_CATEGORY.PACKAGING]: 'Packaging',
    [PRODUCT_CATEGORY.PASTA]: 'Pasta & Noodles',
    [PRODUCT_CATEGORY.PLASTIC]: 'Plastic Pellets',
    [PRODUCT_CATEGORY.POWDERED_BEVERAGES_MIXES]: 'Powdered Beverage Mixes',
    [PRODUCT_CATEGORY.PREPARED]: 'Prepared Foods',
    [PRODUCT_CATEGORY.SNACKS]: 'Snacks',
    [PRODUCT_CATEGORY.SNAKES]: 'Snacks',
    [PRODUCT_CATEGORY.SODA]: 'Soda',
    [PRODUCT_CATEGORY.SOUPS]: 'Soups & Broths',
    [PRODUCT_CATEGORY.SPICES]: 'Spices & Herbs',
    [PRODUCT_CATEGORY.SPORTS_ENERGY_DRINKS]: 'Sports & Energy Drinks',
    [PRODUCT_CATEGORY.TEA_INFUSIONS]: 'Tea & Infusions',
    [PRODUCT_CATEGORY.TOUFU]: 'Tofu, Soy & Vegetarian Products',
    [PRODUCT_CATEGORY.WATER]: 'Water',
    // Note: From BTMS Products
    [PRODUCT_CATEGORY.AUTOMOTIVE]: 'Automotive',
    [PRODUCT_CATEGORY.MOVING_GOODS]: 'Moving Goods',
    [PRODUCT_CATEGORY.CONSTRUCTION_MATERIAL]: 'Construction Material',
    [PRODUCT_CATEGORY.HEAVY_EQUIPMENT]: 'Heavy Equipment',
    [PRODUCT_CATEGORY.FROZEN_GOODS]: 'Frozen Goods',
    [PRODUCT_CATEGORY.AGRICULTURAL_PRODUCT]: 'Agricultural Product',
    [PRODUCT_CATEGORY.GENERAL_CARGO]: 'General Cargo',
    [PRODUCT_CATEGORY.GLASSWARE]: 'Glassware',
    [PRODUCT_CATEGORY.PRODUCT_PACKAGING]: 'Product Packaging',
  },
  product_details: 'Load Details',
  product_type: 'Product Type',
  proforma_invoice: 'Proforma Invoice',
  progressive_pricing_type: 'Progressive',
  proof_of_cancellation: 'Proof of Cancellation Attachment',
  proof_of_cancellation_type: {
    DRIVER_APP_GPS: 'Driver App GPS',
    EMAIL_APPROVAL: 'Approval via Email',
    WHATSAPP_APPROVAL: 'Approval via WhatsApp',
  },
  province: 'Province',
  pt: 'PT',
  puspakom: 'Puspakom Inspection',
  quantities: 'Quantities',
  question_for_kargo: 'Question for Kargo?',
  question_for_kargo_payment: 'Question about Kargo payment?',
  queueing_time: 'Queueing Time',
  readiness_shipment_status_map: {
    [FLEET_MONITORING_STATUS.AVAILABLE]: 'Available',
    [FLEET_MONITORING_STATUS.ONGOING]: 'Ongoing',
    [READINESS_SHIPMENT_STATUS.ABOUT_TO_BE_READY]: 'About to be Ready',
    [READINESS_SHIPMENT_STATUS.READY]: 'Ready',
  },
  real_add_additional_fee: 'Enter Additional Fee',
  receipt_details: 'Receipt Details',
  receipt_number: 'Receipt Number',
  receipt_number_placeholder: 'Enter the shipment receipt number',
  receive_time: 'Receive Time',
  receiver_name: 'Receiver Name',
  recipients_placeholder: 'Type the recipient email. Example: “budi@email.com”',
  reconfirmation: 'Reconfirmation',
  refuse_shipment: 'Refuse Shipment',
  refusing_job: 'Refusing this shipment will affect your performance rating as set in the contract with the customer.',
  regarding: 'Regarding',
  register: 'Register',
  registered_company_name: 'Registered Company Name',
  registered_to_swift_payment_program: 'Registered to the Swift Payment Program',
  reimbursement_percentage: 'Reimbursement Percentage',
  reimbursement_requirement: 'Reimbursement Requirement',
  reimbursement_requirement_description: 'Type reimbursement requirements here',
  reimbursement_requirement_type_map: {
    [REIMBURSEMENT_REQUIREMENT_TYPE.NOT_REIMBURSABLE]: 'Not Reimbursable',
    [REIMBURSEMENT_REQUIREMENT_TYPE.OTHER]: 'Other',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE]: 'Reimbursable',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE_WITH_RECEIPT]: 'Reimbursable with Receipt',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE_WITH_RECEIPT_AND_STAMP]: 'Reimbursable with Receipt and Stamp',
  },
  reimbursement_type: 'Reimbursement Type',
  reimbursement_type_description: 'Type reimbursement type here',
  rejected: 'Rejected',
  rejected_by_shipper: 'Cancelled by Customer',
  rejected_by_vendor: 'Cancelled by Vendor',
  rejected_dof_shipment_list_details: 'Rejected Shipment Details',
  rejected_podf_invoice_details: 'Rejected Invoice Details',
  rejection_category: 'Rejection Category',
  rejection_reason: 'Rejection Reason',
  remaining_lead_time: 'Remaining Lead Time',
  remarks: 'Remarks',
  rent_period: 'Rental Period',
  rental_price: 'Rental Price',
  rental_column_deleted: 'Column has been deleted',
  repayment: 'Repayment',
  repayment_date: 'Repayment Date',
  report: 'Report',
  report_tab: {
    shipmentAcceptance: {
      datetimeRanges: 'Date Ranges',
      process: 'Process',
      doTotal: 'Total DO',
      acceptedDoPercentage: 'DO Acceptance Percentage',
      status: {
        [SHIPMENT_ACCEPTANCE_STATUS.ACCEPTED]: 'DO accepted',
        [SHIPMENT_ACCEPTANCE_STATUS.CANCELLED_BY_SHIPPER]: 'DO cancelled by shipper',
        [SHIPMENT_ACCEPTANCE_STATUS.REJECTED_BY_TRANSPORTER]: 'DO rejected by vendor',
        [SHIPMENT_ACCEPTANCE_STATUS.CONFIRMATION_EXPIRED]: 'DO not confirmed',
      },
    },
    shipmentTimeAccuracy: {
      warehouseLocation: 'Location',
      total_do: 'Total DO',
      status: {
        [SHIPMENT_TIME_ACCURACY_STATUS.AHEAD]: 'Ahead of Time',
        [SHIPMENT_TIME_ACCURACY_STATUS.ONTIME]: 'On Time',
        [SHIPMENT_TIME_ACCURACY_STATUS.LATE]: 'Late',
      },
    },
    vehicle_mileage: {
      average_mileage: 'Average Mileage (Km)',
      title: 'Search Mileage History',
      report_type: 'Report Type',
      tooltip_info: 'How to calculate average mileage?',
      calculate_average_mileage: 'Average Mileage Calculation',
      calculate_total_mileage: 'Total Mileage Calculation',
      total_mileage: 'Total Mileage (Km)',
      route: 'Route',
    },
    datetimeRanges: 'Date Ranges',
    initialScreenTitle: 'Please Input Data You Want to Search',
    total_do: 'Total DO Count',
    total_mileage: 'Total Mileage',
    trip_details: 'Trip Details',
    truckType: 'Fleet Type',
    utilization: {
      actual_utilization_label: 'Total Actual Lead Time',
      allocated_utilization_label: 'Total Target Lead Time',
      avg_completed_shipment_label: 'Average DO Count',
      avg_actual_lead_time: 'Average Actual Lead Time',
      avg_actual_utilization_label: 'Average Actual Utilization',
      avg_allocated_utilization_label: 'Estimated Average Utilization',
      avg_target_lead_time: 'Average Target Lead Time',
      company_working_days: 'Company Working Days',
      current_utilization: 'Current Utilization',
      current_utilization_calculation: 'Current Utilization Calculation',
      do_number: 'DO Number',
      each_month: '/ Month',
      performance_analysis: 'Performance Analysis',
      target_lead_time: 'Target Lead Time',
      target_lead_time_actual: 'Actual Lead Time',
      total_do: 'Total DO',
      total_target_lead_time: 'Target Lead Time',
      total_target_lead_time_actual: 'Actual Lead Time',
      tooltip_content: 'The calculation of the total actual utilization time starts when the driver departs to the first loading location until the unloading is completed at the last location. If there is waiting time between each location, then that waiting time is also counted.',
      tooltip_label: 'How to calculate current utilization?',
      tooltip_title: 'Actual Utilization Calculation',
      utilization_target_label: 'Company Utilization Target',
      utilization_detail: 'Utilization Details',
    },
  },
  request_type: 'Request Type',
  vehicle_report_type_map: {
    [VEHICLE_REPORT_TYPE.TOTAL_KM]: 'Total Mileage',
    [VEHICLE_REPORT_TYPE.PER_ROUTE_KM]: 'Mileage Per Route',
  },
  reset: 'Reset',
  resend_verification_code: 'Resend verification code',
  respond: 'Respond',
  return: 'Return',
  return_deadline: 'Return Deadline',
  return_fee: 'Return Fee',
  return_schedule: 'Return Schedule',
  retype_new_password: 'Retype New Password',
  retype_password: 'Retype Password',
  reupload_file: 'Reupload File',
  reupload_npwp: 'Reupload NPWP',
  reupload: 'Reupload',
  review: 'Review',
  review_document: 'Review Document',
  review_pod: 'Review Unloading Document (POD)',
  review_invoice: 'Review Invoice',
  revised_invoice: 'Revised Invoice',
  revisi_invoice: 'Revise Invoice',
  road_fee: 'Road Fee',
  role: 'Role',
  route: 'Route',
  routes: 'Routes',
  route_type: 'Route Type',
  route_type_map: {
    [ROUTE_TYPES.SINGLE]: 'Single Pickup and Dropoff',
    [ROUTE_TYPES.MULTI]: 'Multiple Pickup/Dropoff',
  },
  safety_req: 'Safety Requirements',
  sales: 'Sales',
  sales_support: 'Sales Support',
  save: 'Save',
  save_and_continue: 'Save & Continue',
  save_and_create: 'Save and Create',
  save_and_exit: 'Save & Exit',
  save_and_send: 'Save & Send',
  save_changes: 'Save Changes',
  save_customer: 'Save Customer',
  save_driver: 'Save Driver',
  save_format: 'Save Format',
  save_location: 'Save Location',
  save_password: 'Save Password',
  save_shipment: 'Save Shipment',
  save_status: 'Save Status',
  save_template: 'Save Template',
  save_warehouse: 'Save Warehouse',
  saved_warehouse: 'Saved Warehouse',
  search: 'Search',
  select_delivery_order_type: 'Select Delivery Type for',
  send_physical_invoice_title: 'Send Physical Invoice',
  scheme: 'Scheme',
  search_address: 'Search Address',
  search_do_number_and_license_number: 'Search DO Number, License Number',
  search_file: 'Search File',
  search_shipment_number: 'Search Shipment Number',
  search_shipment_no_license_plate_serial_no_body_code: 'Search License Plate Number, Shipment Number, Serial Number, Body Code',
  search_shipment_no_license_plate_serial_no: 'Search License Plate Number, Shipment Number, Serial Number',
  search_shipment_non_serial_no: 'Search Shipment Number, Serial Number',
  search_external_id: 'Search External ID',
  search_license_number: 'Search License Number',
  search_vehicle_availablity: 'Search Vehicle Availability',
  second: 'Second',
  second_disbursement: 'Second Disbursement',
  see_details: 'See Details',
  see_document_claim: 'View Claim Document',
  see_invoice_receipt: 'View Invoice Receipt',
  see_less: 'See Less',
  see_list_of_units: 'View List of Units',
  see_more: 'See More',
  see_revised_invoice: 'View Revised Invoice',
  see_the_rest: 'See the Rest',
  select: 'Select',
  select_all: 'Select All',
  select_as: 'select as',
  select_column: 'Select Column',
  select_customer_and_shipments: 'Select Customer and Shipments',
  select_customer_placeholder: 'Select Customer',
  select_customer: '"Select Customer before proceeding..."',
  select_file: 'Select File',
  select_reason_for_cancellation: 'Select reason',
  select_reason_for_rejection: 'Select reason for rejection',
  select_unit: 'Select Unit',
  send_bid: 'Send Bid',
  send_email: 'Send Email',
  send_invoice: 'Send Invoice',
  send_invoice_reminder: 'Send invoice reminder to this contact',
  send_invoice_reminder_every: 'Send invoice reminder every',
  send_invoice_reminder_via: 'Send invoice reminder via',
  send_revise: 'Send Revision',
  send_updated: 'Send Update',
  send_to: 'Send to',
  serial_number: 'Serial No.',
  shipment: 'Shipment',
  shipment_cost: 'Shipment Cost',
  shipment_denied: 'Shipment Denied',
  shipment_details: 'Shipment Details',
  shipment_document_type_map: {
    'claims-returns': 'Claims and Returns',
    'internal-pod': 'Unloading Document (POD)',
    'internal-pol': 'Loading Document (POL)',
    'other-document': 'Other Document',
  },
  shipment_drop: 'Multi-Drop',
  shipment_expense_types: {
    [SHIPMENT_EXPENSE_TYPES.DRIVER_SALARY_EXPENSE]: 'Driver Salary',
    [SHIPMENT_EXPENSE_TYPES.LOADING_EXPENSE]: 'Loading Expense',
    [SHIPMENT_EXPENSE_TYPES.OTHER_EXPENSE]: 'Miscellaneous Expense',
    [SHIPMENT_EXPENSE_TYPES.OVERNIGHT_EXPENSE]: 'Driver Overnight Expense',
    [SHIPMENT_EXPENSE_TYPES.TRAVEL_EXPENSE]: 'Travel Allowance',
    [SHIPMENT_EXPENSE_TYPES.UNLOADING_EXPENSE]: 'Unloading Expense',
  },
  shipment_expenses_table: {
    edit: 'Edit',
  },
  shipment_fee: 'Shipping Fee',
  shipment_fees_table: {
    amount: 'Amount',
    attachment: 'Attachment',
    description: 'Description',
    doInvoice: 'Related Invoice',
    fee_type: 'Fee Type',
    notes: 'Notes',
    related_pr: 'Related PR',
    status: 'Status',
    view_detail: 'View Details',
  },
  shipment_history: 'Trip History',
  shipment_job_status_map: {
    [JOB_STATUSES.UNASSIGNED]: 'Unassigned',
  },
  shipment_no: 'Shipment No.',
  shipment_no_table: 'Shipment No.',
  shipment_number: 'Shipment Number',
  shipment_numbers_selecter: '{{count}} shipment numbers selected',
  shipment_pickup: 'Loading Schedule',
  shipment_point_label_map: {
    [SHIPMENT_POINT_TYPES.DROPOFF]: 'Drop-off Location',
    [SHIPMENT_POINT_TYPES.PICKUP]: 'Pickup Location',
    [SHIPMENT_POINT_TYPES.PICKUP_DROPOFF]: 'Pickup & Drop-off Location',
  },
  shipment_price: 'Trucking Fee',
  shipment_quantities_delivered: 'Quantity Delivered',
  shipment_route: 'Shipping Route',
  shipment_mile_type_map: {
    MID_MILE: 'Mid Mile',
    FIRST_MILE: 'First Mile',
  },
  shipment_status_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrived at Drop-off Location',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Arrived at Pickup Location',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truck Assigned',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Customer',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Confirmation Expired',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Loading Completed',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Unloading Completed',
    [SHIPMENT_STATUSES.NEED_CONFIRMATION]: 'Awaiting Confirmation',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Heading to Drop-off Location',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Heading to Drop-off Location',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Heading to Pickup Location',
    [SHIPMENT_STATUSES.SHIPMENT]: 'In Transit',
    [SHIPMENT_STATUSES.LOADING]: 'Loading',
    [SHIPMENT_STATUSES.UNLOADING]: 'Unloading',
    [SHIPMENT_STATUSES.ONGOING]: 'Ongoing',
    [SHIPMENT_STATUSES.SCHEDULED]: 'Scheduled',
    [SHIPMENT_STATUSES.PLANNED]: 'Truck Not Assigned',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Customer Reassigned Task',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Unfulfilled',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled',
    [SHIPMENT_STATUSES.UPCOMING]: 'Shipment Created',
    [SHIPMENT_STATUSES.VEHICLE_ASSIGNED]: 'Truck Assigned',
    [SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED]: 'Truck Not Assigned',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'Awaiting Verification',
    [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Cancelled by Customer',
    [SHIPMENT_STATUSES['CANCELLED_BY_INTERNAL,CANCELLED_BY_SHIPPER']]: 'Cancelled by Customer',
    [SHIPMENT_STATUSES.UNFULFILLED_BY_TRANSPORTER]: 'Unfulfilled',
    [SHIPMENT_STATUSES.CANCELLED_BY_CUSTOMER]: 'Cancelled by Customer',
    [SHIPMENT_STATUSES.REASSIGNED_BY_CUSTOMER]: 'Customer Reassigned Task',
  },
  shipment_still_on_first_disbursement: 'Shipment still on First Disbursement',
  shipment_summary: 'Shipment Summary',
  shipment_template: 'Shipment Template',
  shipment_type: 'Shipment Type',
  shipment_fulfillment: 'Fulfillment Type',
  shipments_already_on_2nd_disbursement: 'Shipments already on Second Disbursement',
  shipper: 'Shipper',
  shipper_cancellation_fee: 'Shipper Cancellation Fee',
  shipper_fault: 'Shipper Fault',
  shipper_information: 'Shipper Information',
  shipper_list: 'Shipper List',
  shipper_name: 'Shipper Name',
  shipper_name_slash_company: 'Shipper Name / Company',
  shipper_overnight_fee: 'Shipper Overnight Fee',
  shipper_payment_date: 'Shipper Payment Date',
  shipper_payment_info: 'Shipper Payment Info',
  shipper_payment_reminders: '{{reminder}}',
  shipper_price: 'Shipping Price',
  shipper_remarks: 'Remarks',
  shipper_status: 'Shipper Status',
  shipper_status_map: {
    [GENERAL_ENTITY_STATUSES.DEACTIVATED]: 'Inactive',
    [GENERAL_ENTITY_STATUSES.REGISTERED]: 'Active',
    [SHIPPER_STATUSES.ACTIVE]: 'Active',
    [SHIPPER_STATUSES.CHURN]: 'Churn',
    [SHIPPER_STATUSES.INACTIVE]: 'Inactive',
    [SHIPPER_STATUSES.PENDING_ONBOARDING]: 'Onboarding Pending',
    [SHIPPER_STATUSES.REJECTED]: 'Rejected',
    [SHIPPER_STATUSES.SUSPENDED]: 'Suspended',
  },
  shipper_type: 'Shipper Type',
  shipper_types_map: {
    [SHIPPER_TYPES.A1]: 'A1',
    [SHIPPER_TYPES.A2]: 'A2',
    [SHIPPER_TYPES.A3]: 'A3',
  },
  shipping_seal_number: 'Shipping Seal Number',
  show: 'Show',
  show_claims_in: 'Show Claims in',
  show_claims_in_map: {
    [SHOW_CLAIMS_IN.BERITA_ACARA]: 'Incident Report',
    [SHOW_CLAIMS_IN.LOAD_ITEMS]: 'Load Items',
    [SHOW_CLAIMS_IN.POD]: 'Proof of Delivery (POD)',
  },
  show_everything: 'Show Everything',
  show_less: 'Show Less',
  show_more: 'Show More',
  show_qr_code_truck: 'View Truck QR Code',
  signature_name: 'Signature Name',
  signature_optional: 'Signature and Stamp (optional)',
  signature_optional_pbi: 'Digital Signature (optional)',
  signature_required_pbi: 'Digital Signature Required',
  signature_title: 'Title',
  signed: 'Acknowledged',
  sim: 'SIM',
  skip_this_section: 'Skip this section',
  skip: 'Skip',
  sku: 'SKU',
  slot_amount: 'Slot Amount',
  sort: 'Sort',
  sort_column: 'Sort Column',
  sort_label: 'Sort by',
  sort_table: 'Sort Table',
  sort_table_coach_mark: {
    [COACH_MARK_CONTENT.DESCRIPTION]: 'Sort table data based on text (A to Z) or numbers (smallest to largest)',
    [COACH_MARK_CONTENT.TITLE]: 'Sort Table Content',
  },
  special_day_inclusion_type: {
    NATIONAL_HOLIDAYS: 'National Holidays',
    SUNDAYS: 'Sundays',
  },
  sppkp: 'SPPKP',
  start: 'Start',
  start_date: 'Start Date',
  start_loading_date: 'Start Loading Date',
  start_time: 'Start Time',
  status: 'Status',
  status_change_timestamp: 'Status Change Timestamp',
  status_log: 'Status Log',
  stnk: 'STNK',
  stop_duration: 'Stop Duration',
  store: 'Store',
  subject: 'Subject',
  subject_placeholder: 'Type the email subject/title. Example: “Invoice for Shipment DO-123”',
  submission_summary: 'Submission Summary',
  submission_type: 'Type',
  submissions_accepted: 'Submissions Accepted',
  submissions_status: 'Submission Status',
  submissions_submitted: 'Submissions Submitted',
  submit_disbursement_details: 'Submit Disbursement Details',
  submit_leave: 'Yes, leave',
  submit_receipt_results: 'Submit Receipt Results',
  submit_review_results: 'Submit Review Results',
  submitted_bid: 'Submitted Bid',
  submitted_date: 'Submitted Date',
  submitted_payment: 'Submitted Funding',
  submit_date: 'Submission Date',
  subtotal: 'Subtotal',
  suggestion: 'Suggestion',
  summary: 'Summary',
  summary_fee: 'Billing Summary',
  swift_code: 'Swift Code',
  swift_payment: 'Swift Payment',
  swift_payment_empty_state: {
    button: 'Register Now',
    description: 'Register and add customer data for your shipping fleet’s operational funding needs.',
    title: 'Not Registered Yet',
  },
  swift_payment_price_adjustment_type_map: {
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.CLAIM]: 'Claim',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.OTHERS]: 'Others',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.ADDITION_IN_DO_PRICE]: 'Increase in DO Price',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.REDUCE_IN_DO_PRICE]: 'Reduction in DO Price',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.TRANSFER_FEE]: 'Transfer Fee',
  },
  swift_payment_request_status_map: {
    [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'First Disbursement Processed',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'First Disbursement Transferred',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'Second Disbursement Partially Processed',
    [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'Submission On Hold',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'Rejected',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'Submitted',
    [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'Second Disbursement Transferred',
    [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'Second Disbursement In Process',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'Third Disbursement Transferred',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'Third Disbursement In Process',
  },
  swift_payment_section: {
    [SWIFT_PAYMENT_TYPES.DOF]: 'DOF',
    [SWIFT_PAYMENT_TYPES.DOF2]: 'DOF Second Disbursement',
    [SWIFT_PAYMENT_TYPES.PODF]: 'PODF',
  },
  tags: 'Tags',
  take_shipment: 'Take Shipment {{doNumber}}',
  target_utlisation_warning: 'You need to set utilization targets for the route above to predict fleet availability.',
  tax_details: 'Tax Details',
  tax_document: 'Tax Invoice',
  tax_invoice: 'Tax Invoice',
  tax_invoice_number: 'Tax Invoice Number',
  tax_road: 'Tax Road',
  tax_types_of_payment_request_map: {
    vat: 'VAT',
    wht23: 'WHT 23',
  },
  telephone_no: 'Telephone No.',
  template: 'Template',
  template_invoice: 'Invoice Template',
  template_name: 'Template Name',
  terms_and_conditions: {
    agreed: 'I have read and agree to the',
    agreement: 'terms and conditions',
    already_agreed: 'I have read and agree to the terms and conditions',
  },
  terms_of_payments: 'Terms of Payments (TOP)',
  terms_of_payments_in_days: 'Payment Terms in Days',
  terms_of_payment_due_date: 'Due Date',
  terms_of_payments_due_date: 'TOP Due Date',
  things: 'Things',
  third_defrost: 'Third',
  third_disbursement: 'Third Disbursement',
  this_month: 'This Month',
  this_year: 'This Year',
  tila: 'TILA',
  time_to_check_proforma_invoice: 'Proforma Invoice Check Time',
  invoice_performance_tracking_time: 'Invoice Performance Tracking Time',
  timestamp_reference: 'Timestamp Reference',
  tin: 'Tax Identification Number (NPWP)',
  to: 'To',
  to_be_attached_in_invoice: 'To be attached in Invoice',
  to_be_returned_to_shipper: 'To be returned to shipper',
  tonnage: 'Tonnage',
  tonnage_or_cubication: 'Tonnage / Cubication',
  total: 'Total',
  total_en: 'Total',
  total_additional_fee: 'Total Additional Fee',
  total_adjustment: 'Total Adjustment',
  total_adjustments: 'Total Adjustments',
  total_amount: 'Total Amount',
  total_approved_request: 'Total Approved Request',
  total_approved_shipment: 'Total Approved Shipment',
  total_balance: 'Total Balance',
  total_charges: 'Total Charges',
  total_cost: 'Total Cost',
  total_disbursement: 'Total Disbursement',
  total_fee: 'Total Fee',
  total_first_disbursement: 'Total First Disbursement',
  total_first_disbursement_approved: 'Total First Disbursement Approved',
  total_first_disbursement_formula: 'First Disbursement - Kargo Fee - VAT',
  total_invoice: 'Total Invoice',
  total_payment: 'Total Payment',
  total_payment_by_shipper: 'Total Payment by Shipper',
  total_price: 'Total Price',
  total_price_with_tax_item: 'Total Price with Tax',
  total_second_disbursement: 'Total Second Disbursement',
  total_second_disbursement_approved: 'Total Second Disbursement Approved',
  total_shipment: 'Total Shipment',
  total_submission: 'Total Submission',
  total_swift_payment_submission: 'Total Swift Payment Submission',
  total_third_disbursement_approved: 'Total Third Disbursement Approved',
  total_utilization: 'Total Utilization',
  trace_history: 'Journey History',
  tracking_point_url: 'Tracking Link',
  tracking_point_url_placeholder: 'Enter the link',
  transaction_type: 'Transaction Type',
  transport_overnight_fee: 'Transporter Overnight Fee',
  transporter: 'Transporter',
  transporter_cancellation_fee: 'Transporter Cancellation Fee',
  transporter_incentive_amount_type: {
    [TRANSPORTER_INCENTIVE_AMOUNT_TYPE.CREDIT]: 'Incentive Adjustment',
    [TRANSPORTER_INCENTIVE_AMOUNT_TYPE.DEBIT]: 'Incentive Addition',
    [TRANSPORTER_INCENTIVE_AMOUNT_TYPE.DISBURSEMENT]: 'Incentive Disbursement',
  },
  transporter_incentive_business_line: {
    [TRANSPORTER_INCENTIVE_BUSINESS_LINE.ENTERPRISE_MID_MILE]: 'Enterprise Mid-Mile',
    [TRANSPORTER_INCENTIVE_BUSINESS_LINE.ENTERPRISE_REFERRAL]: 'Enterprise Referral',
    [TRANSPORTER_INCENTIVE_BUSINESS_LINE.SME_MID_MILE]: 'SME Mid-Mile',
    [TRANSPORTER_INCENTIVE_BUSINESS_LINE.SME_REFERRAL]: 'SME Referral',
  },
  transporter_list: 'Transporter List',
  transporter_name: 'Transporter Name',
  transporter_notes: 'Notes for Vendor',
  transporter_notes_bidding: 'Notes for Transporter',
  travel_details: 'Travel Details',
  truck: 'Truck',
  truck_body: 'Truck Body',
  truck_documents: 'Truck Documents',
  truck_and_driver: 'Truck & Driver',
  truck_and_driver_detail: 'Truck and Driver Details',
  truck_doc_req: 'Truck Document Requirements',
  truck_fee: {
    [PAYMENT_TYPES.ADDITIONAL_FEE]: 'Additional Fee',
    [PAYMENT_TYPES.SHIPMENT_FEE]: 'Shipping Fee',
    [PAYMENT_TYPES.TOTAL_FEE]: 'Total Fee',
  },
  truck_head: 'Truck Head',
  truck_not_found: 'Truck not found',
  truck_qr_code: 'Truck QR Code',
  truck_request: 'Request',
  truck_required: 'Truck Required',
  truck_requirements: 'Truck Requirements',
  truck_requirement_gdl_option: {
    ACTIVE_GDL: 'Active GDL',
    EXPIRED_GDL: 'Expired GDL',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    PHOTOCOPY_OF_GDL: 'Photocopy of GDL',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_kir_option: {
    ACTIVE_KIR: 'Active KIR',
    EXPIRED_KIR: 'Expired KIR',
    EXPIRED_PROOF_OF_RENEWAL: 'Expired Proof of Renewal',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    VALID_PROOF_OF_RENEWAL: 'Valid Proof of Renewal',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_lembar_pajak_option: {
    ACTIVE_LEMBAR_PAJAK: 'Active Tax Sheet',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    PROOF_OF_DOCUMENTS_OR_LICENSE_RENEWAL: 'Proof of Documents or License Renewal',
    PROOF_OF_EXPIRED_TAX: 'Proof of Expired Tax',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_puspakom_option: {
    ACTIVE_PUSPAKOM_INSPECTION: 'Active Puspakom Inspection',
    EXPIRED_PUSPAKOM_INSPECTION: 'Expired Puspakom Inspextion',
    EXPIRED_PROOF_OF_RENEWAL: 'Expired Proof of Renewal',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    VALID_PROOF_OF_RENEWAL: 'Valid Proof of Renewal',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_sim_option: {
    ACTIVE_SIM: 'Active SIM',
    EXPIRED_SIM: 'Expired SIM',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    PHOTOCOPY_OF_SIM: 'Photocopy of SIM',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_stnk_option: {
    ACTIVE_STNK: 'Active STNK',
    EXPIRED_STNK: 'Expired STNK',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    TRAFFIC_TICKET_PHOTOCOPY: 'Traffic Ticket Photocopy',
    VALID_PROOF_OF_RENEWAL: 'Valid Proof of Renewal',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_tax_road_option: {
    ACTIVE_TAX_ROAD: 'Active Tax Road',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    PROOF_OF_DOCUMENTS_OR_LICENSE_RENEWAL: 'Proof of Documents or License Renewal',
    PROOF_OF_EXPIRED_TAX: 'Proof of Expired Tax Road',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_requirement_vehicle_registration_option: {
    ACTIVE_VEHICLE_REGISTRATION: 'Active Vehicle Registration',
    EXPIRED_VEHICLE_REGISTRATION: 'Expired Vehicle Registration',
    EXPIRED_TRAFFIC_TICKET: 'Expired Traffic Ticket',
    TRAFFIC_TICKET_PHOTOCOPY: 'Traffic Ticket Photocopy',
    VALID_PROOF_OF_RENEWAL: 'Valid Proof of Renewal',
    VALID_TRAFFIC_TICKET: 'Valid Traffic Ticket',
  },
  truck_safety_req: 'Truck Safety Requirements',
  truck_type: 'Truck Type',
  type: 'Type',
  type_vehicle: 'Fleet Type',
  type_of_access: 'Type of Access',
  type_of_company: 'Type of Company',
  type_of_product: 'Product Type',
  type_to_search_driver: 'Type 1 letter to search for a driver',
  type_to_search_license_plate: 'Type 1 letter to search for a license plate',
  unit: 'Unit',
  unit_list: 'Unit List',
  unload_date_and_time: 'Unload Date & Time',
  unload_date: 'Unload Date',
  unload_point: 'Unload {{pointName}}',
  unload_schedule: 'Unload Schedule',
  unload_time: 'Unload Time',
  unload_location: 'Unload Location',
  unload: 'Unload',
  unloading_completion_date: 'Unloading Completion Date',
  unloading_date: 'Unloading Date',
  unloading_fee: 'Unloading Fee',
  unloading_info: 'Unloading Information',
  unloading_time: 'Unloading Time',
  upcoming_shipment: 'Upcoming Shipment',
  updated: 'Updated',
  updated_date: 'Updated Date',
  update_container_info: 'Update Container Information',
  upload: 'Upload',
  upload_attachment: 'Upload Attachment',
  upload_csv: 'Upload CSV',
  upload_document: 'Upload Document',
  upload_do_block_dialog_description: 'Contact Kargo Nexus for Transporters to increase your DO quota',
  upload_do_block_dialog_title: 'DO Quota Exceeded',
  upload_do_block_grace_period_dialog_description: 'You cannot access this feature because your contract period has expired. Contact us to stay connected with all features of Kargo Nexus for Transporters',
  upload_do_block_grace_period_dialog_title: 'Contact us to use this feature',
  upload_driving_licence: 'Upload Driving License',
  upload_file: 'Upload File',
  upload_kir: 'Upload KIR',
  upload_location_fee: 'Upload Location Fee',
  upload_message: 'To upload this file, you must change',
  upload_npwp: 'Upload NPWP',
  upload_pod: 'Upload POD',
  upload_receipt: 'Upload Receipt',
  upload_sppkp: 'Upload SPPKP',
  upload_stnk: 'Upload STNK',
  upload_tax_document: 'Upload Tax Invoice',
  upload_the_id_card: 'Upload ID Card',
  upload_tila: 'Upload TILA',
  uploaded: 'Uploaded',
  uploaded_from: 'Uploaded from',
  use: 'Use',
  use_format: 'Use Format',
  use_template: 'Use Template',
  use_for_timestamp: 'Use for Timestamp',
  use_goods_capacity: 'Use Goods Capacity',
  used_date: 'Used Date',
  user: 'User',
  user_access_type_map: {
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: 'Consignee',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: 'Freight Forwarder',
    [PANTHERA_PIC_DIVISION.CANVASSER]: 'Canvasser',
    [USER_ACCESS_TYPE.ADMIN]: 'Admin',
    [USER_ACCESS_TYPE.FINANCE]: 'Finance Access',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Full Access',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Restricted Access',
    [USER_ACCESS_TYPE.PRICE_RESTRICTED_ACCESS]: 'No Price Access',
  },
  user_status_map: {
    [USER_STATUSES.ACTIVE]: 'Active',
    [USER_STATUSES.INACTIVE]: 'Inactive',
    [USER_STATUSES.REGISTERED]: 'Not Active Yet',
  },
  utilization: 'Utilization',
  utilization_csv_upload_header: 'Upload Route from CSV Document',
  utilization_tab: {
    add_route: 'Add Route',
    add_target_lead_time_per_route: 'Add Target Lead Time Per Route',
    add_title: 'Add Utilization Target Per Route',
    company_working_day: 'Company Working Days',
    change: 'Edit Route',
    edit_target_lead_time_per_route: 'Edit Target Lead Time Per Route',
    edit_title: 'Edit Utilization Target Per Route',
    header_title: 'Total Utilization Target',
    search_placeholder: 'Search for warehouse name...',
    select_placeholder: 'Select',
    success: 'New utilization target successfully added',
    target_lead_time_per_route: 'Target Lead Time Per Route',
    target_lead_time: 'Target Lead Time',
    truck_type: 'Fleet Type',
    unit: 'Per Month',
    utilization_target: 'Utilization Target',
  },
  valid_until: 'Valid Until',
  variant: 'Variant',
  vat_kargo_fee: 'VAT Kargo Fee',
  vehicle: 'Fleet',
  vehicle_availablity: 'Fleet Availability',
  vehicle_availablity_status_map: {
    [VEHICLE_AVAILABILITY_STATUS.AVAILABLE]: 'All Dates Available',
    [VEHICLE_AVAILABILITY_STATUS.PARTIALLY_AVAILABLE]: 'Some Dates Available',
    [VEHICLE_AVAILABILITY_STATUS.UNKNOWN]: 'Set Utilization Target',
    [VEHICLE_AVAILABILITY_STATUS.UNAVAILABLE]: 'Unavailable',
  },
  vehicle_condition: 'Fleet Condition',
  vehicle_body_type: 'Body Type',
  vehicle_is_damaged: 'Vehicle is Damaged',
  vehicle_registration: 'Vehicle Registration',
  vehicle_status_map: {
    ACTIVE: 'Active',
    DEACTIVATED: 'Deactivated',
    DRAFT: 'Draft',
    EXPIRED: 'Expired',
    PENDING: 'Pending',
    REGISTERED: 'Registered',
    REJECTED: 'Rejected',
  },
  vehicle_tracking: 'Vehicle Tracking',
  vehicle_type: 'Truck Type',
  vehicles: 'Fleets',
  vendor: 'Vendor',
  vendor_registration_id: 'Vendor Registration ID',
  verification_code: 'Verification Code',
  verified_user: 'Verified User',
  version: 'Version',
  via_qr_code: 'via QR Code',
  view: 'View',
  view_changes: 'View Changes',
  view_delivery: 'View Delivery',
  view_pod_pol_details: 'View Delivery Order',
  view_notes: 'View Notes',
  view_template: 'View Template',
  waiting_for_admin_approval: 'Waiting for Admin Approval',
  waiting_for_admin_calculation: 'Waiting for Admin Calculation',
  waiting_for_pod: 'POD not yet received by PBI',
  waiting_verification: 'Waiting for Verification',
  warehouse: 'Warehouse',
  warehouse_information: 'Warehouse Information',
  warehouse_name: 'Warehouse Name',
  week: 'Week',
  weight_volume: 'Weight / Volume',
  whatsapp: 'WhatsApp',
  working_days: 'Working Days',
  wps_seal_number: 'Customer Seal Number',
  write: 'Write',
  write_customer_code: 'Enter Customer Code...',
  wrong_email_format: 'Please enter a valid email address.',
  wrong_goods: 'Wrongly Shipped Goods',
  x_capital: 'X',
  ya_cancel: 'Yes, Cancel',
  year_setup: 'Year Setup',
  year_of_manufacturing: 'Year of Manufacturing',
  yes: 'Yes',
  yes_add: 'Yes, Add',
  yes_confirmation: 'Yes, Confirm',
  yes_delete: 'Yes, Delete',
  yes_fulfilled: 'Yes, Fulfilled',
  yes_save_and_exit: 'Yes, Save and Exit',
  yes_submit: 'Yes, Submit',
  delete_attachment: 'Delete Attachment',
  mid_mile: 'Mid Mile',
  first_mile: 'First Mile',
  container_no_table: 'Container No.',
  delivery_order_search_type_map: {
    [DELIVERY_ORDER_SEARCH_TYPES.DO_NUMBER]: 'Delivery Order Number',
    [DELIVERY_ORDER_SEARCH_TYPES.LICENSE_PLATE]: 'License Plate Number',
    [DELIVERY_ORDER_SEARCH_TYPES.CONTAINER_NUMBER]: 'Container Number',
  },
  input_search: 'Enter Search',
  revise_invoice: 'Revise Invoice',
  pbi_container_number: 'Container Number',
  wet_signed_invoice: 'Wet Signed Invoice',
  total_income: 'Total Income',
  tax_base: 'Tax Base',
  income_tax: 'Taxable Income',
  delivery_price: 'Delivery Price',
  ppn_percentage: 'VAT Percentage',
  ppn_type_map: {
    [PPN_TYPE.INCLUDE]: 'Tax Included',
    [PPN_TYPE.EXCLUDE]: 'Tax Excluded',
  },
  ppn_full_text: 'Value-Added Tax (VAT)',
  total_tax: 'Total Tax',
  invoice_date_card: 'Invoice Date',
  wallet: 'Wallet',
};

/* eslint-disable max-len */

import {
  DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS,
  DOCUMENT_UPLOAD_TERMS,
  PBI_PARTNERSHIP_STATUSES,
  PBI_COMPANY_REQUIRED_DOCUMENTS,
  PBI_COMPANY_OPTIONAL_DOCUMENTS,
  PBI_PERSONAL_REQUIRED_DOCUMENTS,
  PBI_VEHICLE_STATUS,
  VEHICLE_DOCUMENT_TYPE,
  PBI_INFO_BANNER_REJECTED_STATUS,
  PBI_DOCUMENT_REJECTION_REASON,
  PBI_DRIVER_VERIFICATION_STATUSES,
  PBI_DRIVER_DOCUMENTS,
  PBI_PERSONAL_OPTIONAL_DOCUMENTS,
} from '~/Configurations/constants';

export default {
  add_contact: 'Add Contact',
  armada_requirement_message: 'Register and select at least one unit to proceed with the registration process',
  armada_section_title: 'Add Fleet Unit',
  armada_section_title_v2: 'Fleet List',
  banner_status_info_map: {
    [PBI_PARTNERSHIP_STATUSES.BLOCKED]: 'You cannot receive DO from PBI until further notice. Please contact Kargo for more information.',
    [PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED]: 'Register to gain access to Kargo Regular Loads.',
    [PBI_PARTNERSHIP_STATUSES.PENDING]: 'We will notify your partnership status within a maximum of 2 working hours.',
    [PBI_PARTNERSHIP_STATUSES.REJECTED]: 'Ensure all documents are complete and re-register to gain access to Kargo Regular Loads.',
    [PBI_PARTNERSHIP_STATUSES.SUSPENDED]: 'You cannot receive DO from PBI for {{days}}x24 hours as your Panthera Biru Indonesia partner account has been suspended.',
    [PBI_PARTNERSHIP_STATUSES.TRUSTED]: 'You have gained access to Kargo Regular Loads and do not need to reconfirm orders.',
    [PBI_PARTNERSHIP_STATUSES.VERIFIED]: 'You have gained access to Kargo Regular Loads.',
  },
  banner_rejected_info_map: {
    [PBI_INFO_BANNER_REJECTED_STATUS.DOCS_REUPLOAD_NEEDED]: 'Need to re-upload documents.',
  },
  bidding_blocked: 'You have been blocked and cannot bid/take loads',
  bidding_confirmation: {
    confirm_bid_success: '{{jobNumber}} successfully taken',
    confirm_modal_content: 'Canceling this delivery after confirmation will affect your chances of getting the next delivery.',
    confirm_modal_title: 'Take Delivery {{jobNumber}}?',
    mass_confirm_content: 'Do you want to confirm {{count}} deliveries?',
    mass_confirm_success: '{{count}} DO successfully confirmed',
    reject_bid_success: '{{jobNumber}} successfully rejected',
    reject_confirmation: 'Yes, Reject',
    reject_modal_content: 'If rejected, you cannot take this delivery.',
    reject_modal_title: 'Reject Delivery {{jobNumber}}?',
  },
  bidding_suspended: 'You cannot bid/take loads for {{days}}x24 hours',
  cancel: 'Cancel',
  company_document_type_map: {
    NIK: 'NIK',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKB]: 'SKB',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]: 'SKT',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]: 'SPPKP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]: 'TDP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS['SPPKP/SKB']]: 'SPPKP',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.AKTA]: 'AKTA',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]: 'NPWP',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NIB]: 'NIB',
    [PBI_COMPANY_REQUIRED_DOCUMENTS['SIUP/SIUJPT']]: 'SIUP/SIUJPT',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.BANK_ACCOUNT_BOOK]: 'Bank Account Book',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.KK]: 'Family Card (KK)',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.SURAT_KUASA]: 'Power of Attorney',
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.KTP]: 'ID Card',
  },
  company_document_title_map: {
    NIK: 'NIK',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKB]: 'SKB',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]: 'SKT',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]: 'SPPKP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]: 'TDP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS['SPPKP/SKB']]: 'SPPKP',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.AKTA]: 'Company Deed (AKTA)',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]: 'Tax Identification Number (NPWP)',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NIB]: 'Business Identification Number (NIB)',
    [PBI_COMPANY_REQUIRED_DOCUMENTS['SIUP/SIUJPT']]: 'SIUP/SIUJPT',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.BANK_ACCOUNT_BOOK]: 'Bank Account Book',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.KK]: 'Family Card (KK)',
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.SURAT_KUASA]: 'Power of Attorney',
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.KTP]: 'Owner ID Card',
  },
  company_document_field_type_map: {
    NIK: 'NIK',
    account_book_name: 'Account Book Holder Name',
    date: '{{field}} Date',
    expiry_date: 'Expiry Date',
    kk_number: 'KK Number',
    ktp_name: 'Name as per ID Card',
    number: '{{field}} Number',
    string: '{{field}}',
  },
  company_document_field_name_map: {
    KTP: 'ID Card',
    NIK: 'NIK',
    NPWP: 'Tax Identification Number (NPWP)',
  },
  company_document_label_map: {
    KTP: 'ID Card',
    NIK: 'NIK',
    number: 'Number',
  },
  company_name: 'Company Name',
  company_information: 'Company Information',
  complete_now: 'Complete Now',
  declare_info_correct: 'I declare all information is correct',
  document_optional_message: {
    kk_number: 'Ensure the name of the Account Holder is listed on the Family Card attachment',
    surat_kuasa: 'Attach a Power of Attorney signed by the Company Owner and Account Holder',
  },
  driver_registration_status_info_map: {
    DRIVER_VERIFICATION_REJECTED: 'Complete the driver data now to execute deliveries from Panthera Biru Indonesia',
    DRIVER_VERIFICATION_PENDING: 'Driver data is being verified by Panthera Biru Indonesia',
    [PBI_PARTNERSHIP_STATUSES.PENDING]: 'Prepare the driver’s ID Card and Driving License for the driver verification process', // documents processing
  },
  driver_verification_status_info: 'Complete and submit your driver data to be able to execute deliveries from Panthera Biru Indonesia',
  driver_verification_in_progress_status_info: 'Driver data is being verified by Panthera Biru Indonesia',
  driver_sim_type: 'Driving License Type {{type}}',
  driver_status_filters_map: {
    [PBI_DRIVER_VERIFICATION_STATUSES.DRAFT]: 'Draft',
    [PBI_DRIVER_VERIFICATION_STATUSES.PENDING]: 'Pending',
    [PBI_DRIVER_VERIFICATION_STATUSES.ACTIVE]: 'Active',
    [PBI_DRIVER_VERIFICATION_STATUSES.REJECTED]: 'Rejected',
  },
  driver_statuses_map: {
    [PBI_DRIVER_VERIFICATION_STATUSES.DRAFT]: 'Not Verified',
    [PBI_DRIVER_VERIFICATION_STATUSES.PENDING]: 'Awaiting Verification',
    [PBI_DRIVER_VERIFICATION_STATUSES.ACTIVE]: 'Active',
    [PBI_DRIVER_VERIFICATION_STATUSES.REJECTED]: 'Rejected',
  },
  email: 'Email',
  expiration_date: 'Expiry Date',
  fill_in_bank_info: 'Fill in Bank Info',
  fill_in_bank_info_v2: 'Bank Info',
  fill_in_company_info: 'Fill in Company Info',
  fill_in_company_info_v2: 'Company Info',
  i_have_read_and_agree: 'I have read and agree',
  input: 'Input',
  leave_page: 'Leave page',
  leave_page_warning: 'All changes you made will be lost if you leave this page',
  leave_this_page: 'Leave this page?',
  n_slot_unit: '{{number}} Slot Unit',
  npwp_number: 'Tax Identification Number (NPWP)',
  npwp_number_format: 'XX.XXX.XXX.X-XXX.000',
  optional: 'Optional',
  owner_email: 'Owner Email',
  owner_info: 'Owner Info',
  owner_name: 'Owner Name',
  pbi_partners: 'Panthera Biru Indonesia Partners',
  phone_number: 'Phone Number',
  postal_code: 'Postal Code',
  placeholder_company_info_input: {
    choose_city: 'Select city',
    company_address: 'Enter company address',
    company_name: 'Enter company name',
    email: 'Enter company email',
    owner_name: 'Enter owner name',
    phone_number: 'Enter company phone number',
    postal_code: 'Enter postal code',
  },
  placeholder_owner_info_input: {
    account_book_name: 'Enter account book owner’s name',
    address: 'Enter address as per ID Card',
    choose_city: 'Select city as per ID Card',
    company_name: 'Enter company name',
    kk_number: 'Enter KK number',
    ktp_name: 'Enter name as per ID Card',
    ktp_nik: 'Enter NIK as per ID Card',
    owner_email: 'Enter owner email',
    owner_name: 'Enter owner name',
    phone_number: 'Enter owner’s mobile number',
    postal_code: 'Enter postal code as per ID Card',

  },
  placeholder_representative_company_contact_input: {
    name: 'Enter representative name',
    position: 'Enter representative position',
    phone_number: 'Enter representative mobile number',
    email: 'Enter representative email',
  },
  program_pbi_partners: 'Panthera Biru Indonesia Partners Program',
  representative: 'Representative {{number}}',
  representative_contact_company: 'Representative Contact',
  representative_email: 'Representative Email',
  representative_email_v2: 'Email',
  representative_info: 'Representative Info',
  representative_name: 'Representative Name',
  representative_name_v2: 'Name',
  representative_of_company: 'I am a representative of this company',
  representative_phone_number: 'Representative Phone Number',
  representative_phone_number_v2: 'Mobile Number',
  representative_position: 'Representative Position',
  representative_position_v2: 'Position',
  send_document_financial_email_representative: 'Send financial documents to this representative’s email',
  sign_up_now: 'Sign Up Now',
  sim_type: 'Driving License Type',
  siup_siujpt_number: 'SIUP/SIUJPT Number',
  success_partnership_form_submitted: 'Partnership Program with Panthera Biru Indonesia has been successfully submitted',
  terms_and_conditions: 'terms and conditions',
  terms_and_conditions_agreed: 'I have read and agree to the terms and conditions',
  today: 'today',
  verification_status_info_map: {
    [PBI_PARTNERSHIP_STATUSES.BLOCKED]: 'You cannot receive DO from PBI until further notice. Contact Kargo for more information.',
    [PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED]: 'Register to gain access to Kargo Regular Loads.',
    [PBI_PARTNERSHIP_STATUSES.PENDING]: 'We will notify your partnership status within a maximum of 2 working hours.',
    [PBI_PARTNERSHIP_STATUSES.REJECTED]: 'Ensure all documents are complete and re-register to gain access to Kargo Regular Loads.',
    [PBI_PARTNERSHIP_STATUSES.SUSPENDED]: 'You cannot receive DO from PBI for {{days}}x24 hours as your Panthera Biru Indonesia partner account has been suspended.',
    [PBI_PARTNERSHIP_STATUSES.TRUSTED]: 'As a trusted user, you have gained access to Kargo Regular Loads and do not need to reconfirm orders.',
    [PBI_PARTNERSHIP_STATUSES.VERIFIED]: 'You have gained access to Kargo Regular Loads.',
  },
  verification_status_map: {
    [PBI_PARTNERSHIP_STATUSES.BLOCKED]: 'Blocked',
    [PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED]: 'Not registered',
    [PBI_PARTNERSHIP_STATUSES.PENDING]: 'Verification in progress',
    [PBI_PARTNERSHIP_STATUSES.REJECTED]: 'Partnership application rejected',
    [PBI_PARTNERSHIP_STATUSES.SUSPENDED]: 'Suspended',
    [PBI_PARTNERSHIP_STATUSES.TRUSTED]: 'Trusted user',
    [PBI_PARTNERSHIP_STATUSES.VERIFIED]: 'User successfully verified',
  },
  view_detail: 'View Detail',
  validity_expires_in: 'Validity expires in {{days}}',
  validity_expired: 'No longer valid since {{date}}',
  update_successfully_requested: 'Update successfully requested',
  upload_additional_documents: 'Upload Additional Documents',
  upload_additional_documents_v2: 'Additional Documents',
  upload_required_documents: 'Upload Required Documents',
  upload_required_documents_v2: 'Required Documents',
  upload: 'Upload',
  upload_document_map: {
    // REQUIRED DOCS
    KTP: 'ID Card',
    NIP: 'NIK',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]: 'Tax Identification Number (NPWP)',
    [PBI_COMPANY_REQUIRED_DOCUMENTS.SIUP]: 'SIUP',
    // OPTIONAL DOCS
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]: 'SKT',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]: 'SPPKP',
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]: 'TDP',
  },
  upload_photo_general_instructions: 'General Instructions',
  upload_photo_message: 'Upload {{name}} Photo',
  upload_photo_rejection_reason_map: {
    [PBI_DOCUMENT_REJECTION_REASON.BLUR]: 'Blurred',
    [PBI_DOCUMENT_REJECTION_REASON.DOCUMENT_INCOMPLETE]: 'Document Incomplete',
    [PBI_DOCUMENT_REJECTION_REASON.EXPIRED]: 'Expired',
    [PBI_DOCUMENT_REJECTION_REASON.OTHER]: 'Other: {{reason}}',
    [PBI_DOCUMENT_REJECTION_REASON.WRONG_DOCUMENT]: 'Wrong Document',
  },
  upload_photo_terms: '{{name}} Photo Requirements',
  upload_instructions: {
    [PBI_COMPANY_REQUIRED_DOCUMENTS.AKTA]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.KTP]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NPWP]: {
      photo_terms: [
        'The NPWP number and validity period are clearly readable.',
        'Ensure the NPWP validity period is more than 5 months.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_REQUIRED_DOCUMENTS['SIUP/SIUJPT']]: {
      photo_terms: [
        'The address is clearly readable.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
      ],
    },
    [PBI_COMPANY_REQUIRED_DOCUMENTS.NIB]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKT]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS['SPPKP/SKB']]: {
      photo_terms: [
        'The NPWP number and validity period are clearly readable.',
        'Ensure the NPWP validity period is more than 5 months.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SKB]: {
      photo_terms: [
        'Ensure the validity period is clearly visible.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.SPPKP]: {
      photo_terms: [
        'The NPWP number and validity period are clearly readable.',
        'Ensure the NPWP validity period is more than 5 months.',
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_COMPANY_OPTIONAL_DOCUMENTS.TDP]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.BANK_ACCOUNT_BOOK]: {
      photo_terms: [
        'Ensure the page showing the name and account number is clearly visible.',
        'The account book holder’s name must match the registered name.',
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.KK]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.KK]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_REQUIRED_DOCUMENTS.SURAT_KUASA]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_PERSONAL_OPTIONAL_DOCUMENTS.SURAT_KUASA]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
    [PBI_DRIVER_DOCUMENTS.SIM]: {
      photo_terms: [
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_VALIDITY_CLEAR,
        DOCUMENT_UPLOAD_TERMS.DOCUMENT_NAME_SAME_AS_REGISTERED_NAME,
      ],
      general_instructions: [
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_ORIGINAL,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_CLEAR,
        DOCUMENT_UPLOAD_GENERAL_INSTRUCTIONS.DOCUMENT_NOT_FLASHED,
      ],
    },
  },
  onboarding_steps: {
    benefits: {
      dialog_title: 'Benefits of the Panthera Biru Indonesia Partners Program',
      content_title: 'Gain Access to Kargo Regular Loads',
      content_description: 'Enjoy more benefits and full access to Kargo Regular Loads',
      benefit_list: {
        1: {
          title: 'Take Loads Instantly',
          description: 'Without bidding and at the price in the app',
        },
        2: {
          title: 'Track Driver Location Anytime',
          description: 'Based on driver position with the app',
        },
        3: {
          title: '50:50 Payment Scheme Options',
          description: 'Faster to optimize your cash flow',
        },
      },
    },
    terms_conditions: {
      dialog_title: 'Terms and Conditions of the Panthera Biru Indonesia Partners Program',
      terms: {
        1: {
          title: 'Vendor Membership Terms & Conditions',
          terms: {
            1: 'Must complete the legality documents and your truck in the Kargo Nexus for Transporters app before taking loads.',
            2: 'Ensure your partner drivers have downloaded the Kargo Partner app while taking Kargo loads.',
          },
        },
        2: {
          title: 'Load Insurance',
          terms: {
            1: 'The displayed price will be deducted for load insurance with a premium of <strong>IDR 40,000 (Forty Thousand Rupiah)</strong> which will only cover claim costs up to <strong>IDR 20,000,000 (Twenty Million Rupiah) per order.</strong>',
            2: 'Insurance will cover 95% of the total claim <strong>up to IDR 20,000,000,</strong> and 5% of the claim value (min. IDR 25,000, max. IDR 1,000,000) is borne by the Transporter Partner.',
            3: 'For claims below IDR 65,000, a 5% deduction (min IDR 25,000 and max IDR 1,000,000) will not be applied, provided the Shipping Letter is returned on time (According to the Shipping Letter conditions).',
            4: 'Insurance does not cover claims due to missing/lost goods without evidence (e.g., police report) or overcharged goods charged by the shipper. Therefore, it is expected from the partner to prepare complete evidence (e.g., photos/videos/return receipts) to be submitted to the shipper.',
            5: 'All decisions regarding insurance are at the discretion of the insurance company, and Kargo cannot be held accountable.',
          },
        },
        3: {
          title: 'Payment',
          terms: {
            1: 'On-Call payments can be arranged with TOP, or 100% at the end or 50% after loading and 50% after unloading (No TOP).',
            2: 'Vendor partners with NPWP will be subject to a 2% PPh deduction. Transporter partners without NPWP will be subject to a 4% PPh deduction.',
            3: 'Please attach the SKB at the beginning, if any. If until the driver placement process is complete and the tax documents are not attached, then the tax provisions will be applied according to the documentation, subject to the applicable tax provisions in Indonesia.',
            4: 'Vendor partners with Taxable Entrepreneur (PKP) status are required to upload PKP endorsement documents.',
            5: 'If until the driver placement process is complete and the documents are not attached, the tax provisions will be applied according to the documentation, subject to the applicable tax provisions in Indonesia.',
            6: 'Please confirm your On-Call Payment preference and Business Status via email to <strong>vendormanagement@kargo.tech</strong> with the format:',
            7: 'Account Name',
            8: 'Email:',
            9: 'On-Call Payment Options: 50:50 / TOP ... days (Choose one)',
            10: 'Business Status: PKP and SKB / Non-PKP / Individual with NPWP / Individual without NPWP (Choose one)',
            11: 'Transporter partners without TOP will receive the first 50% DP <strong>when the Loading Shipping Letter has been properly photographed and approved by Kargo through the Application (provided the partner driver uses the Kargo Partner app properly and correctly)</strong>. Payment will be released on the same day if the Loading Shipping Letter is received <strong>before 15:00 WIB.</strong>',
            12: 'Transporter partners with TOP will receive full 100% payment <strong>when the Loading and Unloading Shipping Letters have been properly photographed and received by Kargo at Kargo Pitstop (provided the partner driver uses the Kargo Partner app properly and correctly)</strong>. Payment will be released on the same day if the Shipping Letter is received <strong>before 15:00 WIB.</strong>',
          },
        },
        4: {
          title: 'Claim Conditions',
          terms: {
            1: 'Document (photo/video) the situation of the unit after loading & unloading, product arrangement, unit seal, etc., so that if a claim occurs, the documentation can be submitted for reconsideration to the Shipper.',
            2: 'Transporter partners who <strong>do not participate in the Load Insurance program will be fully responsible for claim costs.</strong>',
            3: 'Claim cost deductions and 5% from insurance will be made during the <strong>final payment process.</strong>',
            4: 'The Claim Report signed by the partner driver will be considered final, so please check the quantity and notes listed on the Claim Report carefully.',
            5: 'The final claim decision remains with the Shipper, so the claim amount needs to wait for the Shipper\'s decision first.',
            6: 'For claims below IDR 65,000, a 5% deduction (min IDR 25,000 and max IDR 1,000,000) will not be applied, provided the Shipping Letter is returned on time (According to the Shipping Letter conditions).',
            7: 'If directly instructed by the warehouse to return, the return receipt stating that the goods have been received by the warehouse (mandatory) & photos of the goods have been returned/received (if any) need to be sent to Kargo.',
          },
        },
        5: {
          title: 'Payment Delay Procedure',
          terms: {
            1: 'Transporter partners are expected to monitor that the partner driver always uses the Kargo Partner app when taking DO from Kargo Tech.',
            2: 'This is because there is a payment delay of up to 10 working days if the partner driver does not use the app. Here is the explanation:',
          },
        },
        6: {
          title: 'Partner Driver only uses the App during the loading process.',
          subterms: {
            1: {
              title: 'Transporter with 50:50 payment',
              terms: {
                1: '<strong>First Payment</strong> - Paid after the Loading Shipping Letter is received and approved by the Kargo team via the App',
                2: '<strong>Final Payment</strong> - Paid 10 working days after all Shipping Letters and other documents (such as Invoices and Tax Invoices, if needed) are received at the Pitstop.',
              },
            },
            2: {
              title: 'Transporter with TOP payment',
              terms: {
                1: 'First Payment and Final Payment are paid according to the TOP of each Partner with an additional (+) 10 working days after all Shipping Letters and other documents (such as Invoices and Tax Invoices, if needed) are received and approved by the Kargo team via the App and received by the Kargo team at the Pitstop.',
                2: 'Payment simulation: If the transporter has a TOP of 5 days, then a total of 15 working days will be paid.',
              },
            },
          },
        },
        7: {
          title: 'Partner Driver only uses the App during unloading',
          subterms: {
            1: {
              title: 'Transporter with 50:50 payment',
              terms: {
                1: 'The first payment will be made together with the final payment after all Shipping Letters and other documents (such as Invoices and Tax Invoices, if needed) are received and approved by the Kargo team via the App and received at the Pitstop.',
              },
            },
            2: {
              title: 'Transporter with TOP payment',
              terms: {
                1: 'First Payment and Final Payment are paid according to the TOP of each Partner with an additional (+) 10 working days after all Shipping Letters and other documents (such as Invoices and Tax Invoices, if needed) are received and approved by the Kargo team via the app and received at the Pitstop.',
                2: 'Payment simulation: If the transporter has a TOP of 5 days, then a total of 15 working days will be paid.',
              },
            },
          },
        },
        8: {
          title: 'Partner Driver does not use the Kargo Partner App at all',
          subterms: {
            1: {
              title: 'Transporter with 50:50 payment',
              terms: {
                1: 'First Payment and Final Payment will be made (+) 10 working days provided all Shipping Letters and other documents (such as Invoices and Tax Invoices, if needed) have been received by Kargo either via the App or at the Pitstop.',
              },
            },
            2: {
              title: 'Transporter with TOP payment',
              terms: {
                1: 'First Payment and Final Payment are paid according to the TOP of each Partner with an additional (+) 10 working days after all Shipping Letters and other documents (such as Invoices and Tax Invoices, if needed) are received and approved by the Kargo team via the app and received at the Pitstop.',
                2: 'Payment simulation: If the transporter has a TOP of 5 days, then a total of 15 working days will be paid.',
              },
            },
          },
        },
        9: {
          title: 'Return Address for Shipping Letters and Invoice Submission',
          subterms: {
            1: {
              title: 'Shipping Letters and Invoices can be sent to the address below:',
              terms: {
                1: 'Pitstop Cibitung - for shipments to West Java and surrounding areas Kawasan Industri MM2100, Jl. Kalimantan, Gandamekar, Kec. Cikarang Bar., Bekasi, Jawa Barat 17530',
                2: 'Pitstop Bawen - for shipments to Central Java and surrounding areas Kargo Public Parking, Jl. Raya Semarang - Bawen Km 32, Jl. Soekarno - Hatta No.Kel, Harjosari, Kec. Ambarawa, Semarang, Central Java 50661',
                3: 'Pitstop Tangerang - for shipments in the Tangerang area and surrounding areas Jl. MH. Thamrin, RT.002/RW.001, Cikokol, Kec. Tangerang, Kota Tangerang, Banten 15117',
                4: 'Pitstop Pasuruan - for shipments in the Pasuruan area and surrounding areas Kargo Public Parking, Jl. Raya Surabaya - Malang Desa No.Km. 48, Petung Sari, Tawang Rejo, Kec. Pandaan, Pasuruan, East Java 67156',
              },
            },
          },
        },
        10: {
          title: 'Late Return Procedure for Shipping Letters',
          terms: {
            1: 'The return of POD/Shipping Letters is a maximum of H+3 after the unloading process. A fine of IDR 100,000 per day of delay will be applied until the POD is returned to the Kargo team (the fine starts on the 4th day).',
            2: 'POD that is not returned/not received by Kargo for more than 7 days will be considered lost and the Transporter Partner will be fined as follows:',
            3: 'Transporter Partners with 50%-50% payment will not receive the final payment for the trip.',
            4: 'Transporter Partners with TOP or 100% at the end payment will have 50% of the trip cost related to the missing POD deducted.',
            5: 'Transporter Partners who lose POD will only receive 50% payment on trips related to that POD.',
            6: 'If the Transporter Note states "Transporter Does Not Need to Send the Shipping Letter to Kargo", then the POD will be handled directly between the Driver/Transporter and the Shipper. Any additional costs will be resolved directly with the Shipper.',
          },
        },
        11: {
          title: 'Invoice Issuance',
          terms: {
            1: 'Transporter Partners create invoices addressed to PT Panthera Biru Indonesia with the company letterhead, signature, wet stamp, and seal.',
            2: 'Invoices for DO using the Kargo Transporter App (through bidding) should be separated from DO not through the Kargo Transporter App (via offline/Whatsapp group).',
            3: 'Always attach a Tax Invoice to each invoice sent.',
            4: 'It is advisable to separate the Invoice for trip payments from payments for loading, unloading (TKBM) costs, and accommodation fees.',
            5: 'Kargo may refuse to make payments for Invoices that Kargo considers incomplete until considered complete.',
          },
        },
        12: {
          title: 'Payment Procedure',
          terms: {
            1: 'Transporter Partners are expected to send invoices in softcopy to <strong>paymentteam@kargo.tech</strong>',
            2: 'The payment process for invoices for DO without claims will take 0-7 working days after the invoice is received by Kargo.',
            3: 'The payment process for invoices for DO with claims will take longer. The final payment process for DO with claims is estimated to be 1-30 working days from the Invoice being received due to waiting for confirmation from the shipper.',
            4: 'Transporter Partners registered as <strong>Taxable Entrepreneurs (PKP) with Kargo payment of 50% down payment and 50% final payment are encouraged to issue Invoices and Tax Invoices at the final payment.</strong>',
            5: 'Transporter Partners are also encouraged to separate Invoices for payment between yellow plates and black plates (if any), for the convenience of payment processes and related taxation.',
          },
        },
        13: {
          title: 'Payment Assistance Center',
          terms: {
            1: 'All forms of questions, complaints, reports, and payment information will be informed or responded to via email to facilitate the transporter and Kargo in tracking and responding quickly.',
            2: 'Email that can be used by the transporter to submit questions, complaints, and payment reports: <strong>paymentteam@kargo.tech</strong>',
            3: 'There are criteria that must be met by the transporter in making reports or complaints, namely: a. Clear subject. b. In the email body, include the DO number or at least the shipment date, car plate number, shipment origin, and destination. This aims to make it easier for us to check.',
          },
        },
        14: {
          title: 'Miscellaneous',
          terms: {
            1: 'Kargo can change or adjust the content of these terms and conditions from time to time if deemed necessary. All adjustments or changes apply from when they are displayed.',
          },
        },
      },
    },
  },
  stnk: 'Vehicle Registration Certificate (STNK)',
  stnk_upload: '(2 Photos)',
  stnk_expiry_date: 'STNK Expiry Date',
  stnk_tax_expiry_date: 'Tax Expiry Date (per year)',
  stnk_expiry_warning: 'STNK needs to be renewed',
  kir: 'Vehicle Inspection Certificate (KIR)',
  kir_upload: '(3 Photos)',
  kir_expiry_date: 'KIR Expiry Date',
  kir_number: 'Inspection Number',
  kir_expiry_warning: 'KIR needs to be renewed',
  stnk_kir_expiry_warning: 'STNK and KIR need to be renewed',
  about_to_expired: 'Validity expires in {{days}} days',
  is_expired: 'No longer valid since {{date}}',
  armada_upload_dialog: {
    [VEHICLE_DOCUMENT_TYPE.STNK]: {
      title: 'Upload STNK Photo',
      info: 'Ensure the STNK is taken out of the plastic and the photo is not blurry',
      guide: {
        0: {
          condition: 'Page showing the Vehicle License Plate Number',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/stnk-1.png',
        },
        1: {
          condition: 'Page showing Dimensions/Box Size',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/stnk-2.png',
        },
      },
    },
    [VEHICLE_DOCUMENT_TYPE.KIR]: {
      title: 'Upload KIR Photo',
      info: 'Ensure the KIR is still valid for at least 1 (one) month from the time you register on this app.',
      guide: {
        0: {
          condition: 'Page showing the Vehicle License Plate Number',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/kir-1.png',
        },
        1: {
          condition: 'Page showing Dimensions/Box Size',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/kir-2.png',
        },
        2: {
          condition: 'Page showing KIR validity period',
          photo: 'https://kg-glb-tms-asset.s3.ap-southeast-1.amazonaws.com/pbi-verification-guide/kir-3.png',
        },
      },
    },
  },
  vehicle_document_completion: {
    0: 'Documents are incomplete',
    1: 'Documents are incomplete',
    2: 'Documents are complete',
  },
  vehicle_status: {
    [PBI_VEHICLE_STATUS.ACTIVE]: 'Active',
    [PBI_VEHICLE_STATUS.DRAFT]: 'Not Verified',
    [PBI_VEHICLE_STATUS.PENDING]: 'Awaiting Verification',
    [PBI_VEHICLE_STATUS.REJECTED]: 'Rejected',
  },
};

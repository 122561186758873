/* eslint-disable max-len */

import {
  FIRST_MILE_USER_ACCESS_TYPE,
  PBI_PARTNERSHIP_STATUSES,
  USER_ACCESS_TYPE,
  SHIPMENT_INVOICE_TYPE,
  INVOICE_PAYMENT_STATUS,
  CUSTOMIZABLE_INVOICE_STATUSES,
} from '~/Configurations/constants';

export default {
  accept_successfuly_submitted: 'You have successfully submitted a request for {{doNumber}}',
  activate_vehicle_alert_warning: 'You have marked the vehicle as Having Issues. This vehicle will be Unavailable. Please provide a description in the field below.',
  actual_time: 'Actual Arrival Time',
  add_account_helper_text: 'Add a bank account to be listed on the invoice',
  add_prices_profile: 'Let’s add shipping costs to complete your shipper profile',
  add_shipper_profile: 'Let’s add location fees to complete your shipper profile',
  already_have_an_account: 'Already have an account?',
  attachment_invoice_message: 'The following attachments are sent along with the invoice via email',
  back_navigation_alert_desc: 'All changes you made will be lost if you leave this page',
  bank_account_successfully_saved: 'Bank account saved',
  bank_is_valid: 'Ensure your bank information is valid, as all payments from customers will be forwarded to this account.',
  before_preceded_by_another_vendor: 'before you are preceded by another vendor',
  being_damaged: 'Being Damaged',
  bid_loose_notification_msg_end: 'has not been selected.',
  bid_loose_notification_msg_start: 'Your bid for',
  bid_too_high_msg: 'The maximum bid is {{amount}}',
  bid_win_notification_msg_end: 'was selected!',
  bid_win_notification_msg_start: 'Congratulations, your bid for',
  btms_do: {
    claim_radio_info_message: 'Some items may get damaged during shipping, so you need to send a claim. Does this delivery have any item claims?',
    driver_assignment_changed_message: 'Your truck details change is being processed by Kargo. Contact Kargo to expedite verification.',
    driver_assignment_cancel_message: 'You can modify truck details again by canceling this change first.',
  },
  btms_invoice: {
    banner_status_description_map: {
      [INVOICE_PAYMENT_STATUS.SUBMITTED]: 'Print and send the physical invoice to PT Panthera Biru Indonesia',
      [INVOICE_PAYMENT_STATUS.APPROVED]: 'Print and send the physical invoice to PT Panthera Biru Indonesia',
      [INVOICE_PAYMENT_STATUS.REJECTED]: 'There is an error with {{revise_item}}. Please send a revised invoice so the PT. Panthera Biru Indonesia team can correct the invoice.',
      [INVOICE_PAYMENT_STATUS.REVISION_CREATED]: 'Invoice revision created. The invoice with number <0>{{invoice_number}}</0> has been automatically canceled',
      [INVOICE_PAYMENT_STATUS.REVISION_SUBMITTED]: 'Revised invoice has been submitted and is under review. The invoice with number <0>{{invoice_number}}</0> has been automatically canceled',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_SENT]: 'The physical invoice is being shipped. You will see a status update once the invoice has been received',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_RECEIVED]: 'The physical invoice has been received and is under review by PT Panthera Biru Indonesia',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_APPROVED]: 'Payment is being processed and will be scheduled soon. You will see a status update once the payment has been scheduled',
      [INVOICE_PAYMENT_STATUS.ON_HOLD]: 'The physical invoice is missing a stamp. Please resend the physical invoice with a stamp and update the tracking number in your invoice details',
      [INVOICE_PAYMENT_STATUS.CANCELLED]: 'The invoice was automatically canceled and replaced with a new one due to a revision. New invoice number: <0>{{invoice_number}}</0>',
      [INVOICE_PAYMENT_STATUS.PAYMENT_REQUESTED]: 'Payment will soon be sent to the account number listed',
      [INVOICE_PAYMENT_STATUS.PAID]: 'A payment of {{invoice_amount}} has been sent',
    },
    banner_status_title_map: {
      [INVOICE_PAYMENT_STATUS.SUBMITTED]: 'Digital Invoice Under Review',
      [INVOICE_PAYMENT_STATUS.APPROVED]: 'Digital Invoice Approved',
      [INVOICE_PAYMENT_STATUS.REJECTED]: 'Revision Required',
      [INVOICE_PAYMENT_STATUS.REVISION_CREATED]: 'Revision Created',
      [INVOICE_PAYMENT_STATUS.REVISION_SUBMITTED]: 'Revision Under Review',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_SENT]: 'Physical Invoice Sent',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_RECEIVED]: 'Physical Invoice Under Review',
      [INVOICE_PAYMENT_STATUS.PHYSICALLY_APPROVED]: 'Physical Invoice Approved',
      [INVOICE_PAYMENT_STATUS.ON_HOLD]: 'Invoice On Hold',
      [INVOICE_PAYMENT_STATUS.CANCELLED]: 'Invoice Canceled',
      [INVOICE_PAYMENT_STATUS.PAYMENT_REQUESTED]: 'Payment Processing',
      [INVOICE_PAYMENT_STATUS.PAID]: 'Invoice Paid',
    },
    faktur_pajak_need_to_be_uploaded: 'Please upload the tax invoice promptly so we can process this invoice payment',
    faktur_pajak_not_uploaded_banner: 'Tax Invoice not uploaded',
    submit_invoice_dialog_description: 'Are you sure you want to submit this digital invoice?',
    submit_invoice_disabled_reason: 'Upload a Tax Invoice to submit the digital invoice to PT Panthera Biru Indonesia (Kargo)',
    submit_invoice_success_message: 'Digital Invoice successfully submitted. The invoice will be reviewed next',
  },
  bulk_upload_csv: {
    csv_upload_msg: '2. Fill in the data according to the delivery. Columns with a light blue background are required.',
    csv_upload_template_msg: '4. Then, upload the CSV document from the filled-in template here',
    invalid_format: 'CSV Template Error',
    invalid_separator: 'CSV Separator Error',
    check_csv_upload_file_description: 'Check the CSV document you uploaded below.',
    csv_download_excel_template: '3. Download the Excel document you have filled in as a CSV',
    csv_download_template_description: '1. Download the Excel document template.',
    csv_separator_msg1: 'Some devices may have different separators for CSV files\n',
    csv_separator_msg2: 'Please select the CSV separator you are currently using (comma, semicolon, etc.)',
    csv_separator_msg3: 'The standard separator generally used is a comma (,)',
    csv_separator_msg4: 'Note: You can see the current CSV separator by opening the file in Notepad (or other text editor applications)',
    csv_upload_description: 'Currently, the template is only for shipments with single loading and unloading points.',
    invalid_separator_description: 'Please select the appropriate CSV Separator for your device.',
    invalid_format_description: 'Please use the appropriate CSV Template as provided.',
  },
  bulk_upload_excel: {
    check_excel_upload_file_description: 'Check the Excel document you uploaded below.',
    excel_download_template: '1. Download the Excel document',
    excel_fill_data_msg: '2. Fill in the data according to the delivery.',
    excel_upload_from_template: '3. Then, upload the Excel document from the filled-in template here',
    invalid_format: 'Excel Template Error',
    invalid_headers_description: 'Please use the appropriate Excel Template as provided.',
  },
  calculate_average_mileage_info: 'Calculation is generated from the total distance traveled on the searched route divided by the number of DOs on that route. Travel distances outside of DO will not be counted. For example, if the driver has completed a DO and then takes the vehicle to a workshop, it will not be counted.',
  calculate_total_mileage_info: 'The calculation is obtained from the total distance traveled by each vehicle to and from the DO location. Travel distances outside of DO will not be counted. For example, if the driver has completed a DO and then takes the vehicle to a workshop, it will not be counted.',
  cancel_btms_do_warning: 'If canceled, your account will be blocked from receiving offers from Panthera Biru Indonesia for 1 x 24 hours',
  cancel_bid_warning_msg: 'If canceled, the customer will not be able to view and select your offer',
  cancel_do_warning: 'This shipment will remain saved, but it will not be able to be reactivated.',
  cancel_do_warning_new: 'This shipment will be moved to history and will not be able to be reactivated',
  cancel_this_offer: 'Cancel this offer?',
  cannot_be_used: 'Cannot Be Used',
  cannot_change_status_shipment_to_complete: 'Cannot change status to Shipment Complete. Contact Shipper to complete the shipment',
  change_shipment_status_message_title: 'Changing status will....',
  choose_account_number: 'Choose Account Number',
  choose_customer: 'Choose Customer',
  choose_customer_pic: 'Choose Customer PIC',
  choose_date: 'Choose Date',
  choose_fee_type: 'Choose Fee Type',
  choose_plate_color: 'Choose Plate Color',
  choose_shipment_to_create_invoice: 'Select shipment from one customer',
  choose_type_of_goods: 'Choose Type of Goods',
  choose_shipment_number: 'Select Shipment No.',
  confirmation_failed: 'You cannot reconfirm this shipment.',
  confirmation_in_progress: 'Confirmation is in progress',
  created_by_table: 'Created by {{name}} on {{date}}',
  customer_central_address_help_field: 'This address will be displayed as the customer address when you create an invoice',
  deactivate_vehicle_alert_warning: 'You will change the vehicle status to Good condition. This vehicle will be ready for shipment.',
  delete_do_template_warning: 'The previously filled-in form data will be permanently deleted and you will need to re-enter new shipment data.',
  delete_invoice_number_template_question: 'Delete invoice number format?',
  delete_invoice_number_template_warning: 'This customer code will be deleted and cannot be restored. Delete anyway?',
  delete_list_invoice_number_template_warning: 'This invoice number format will be deleted and cannot be restored. Delete anyway?',
  delete_shipment_from_invoice_warning: 'The shipment and documents you have included for this Invoice will be lost.',
  do_already_expired_msg: 'Sorry, shipment {{doNumber}} is not available.',
  do_already_taken_msg: 'Sorry, shipment {{doNumber}} is not available because it has already been accepted.',
  do_already_expired_msg_none: 'Sorry, this shipment is not available.',
  do_already_taken_msg_none: 'Sorry, this shipment is not available because it has already been accepted.',
  do_container_update_success: 'Container Successfully Updated',
  do_created_successfully: 'DO successfully created.',
  do_late_warning: 'Another DO schedule that might be late',
  do_expired_grace_period_reminder_banner_description: 'You still have access to your Kargo Nexus account until {{date}}. Contact us to stay connected with all Kargo Nexus for Transporters features',
  do_expired_grace_period_reminder_banner_title: 'Your contract has been inactive since {{date}}',
  do_expired_today_reminder_banner_description: 'Contact us to continue connecting with all Kargo Nexus for Transporters features',
  do_expired_today_reminder_banner_title: 'Your contract period ends today',
  documents_will_soon_be_verified_result_will_be_notified: 'The documents you have uploaded will soon be verified by the Kargo team. Verification results will be notified via this page or via the registered email',
  does_document_meet_requirements: 'Does the document meet the requirements?',
  dof2_total_disbursement_formula: 'First Disbursement + Second + Third',
  dof2_total_second_disbursement_formula: 'First Disbursement + Second',
  dont_have_an_account_yet: 'Don’t have an account yet?',
  download_locations_csv_msg: 'Or download the template ',
  empty_notification: 'No notifications yet',
  enter_invoice_number: 'Enter Invoice Number',
  enter_verification_code_that_we_sent_to: 'Enter the verification code we sent to',
  errors: {
    backend: {
      acceptDeliveryOrder: {
        9: 'Sorry, this shipment is not available because it has already been accepted',
      },
      approveDofRequest: {
        83: 'Fast payment request is not registered in the system',
        84: 'One or more shipments that were rejected are not part of this payment request',
      },
      assignPlannedShipment: {
        108: 'Shipment is not assignable (current status is not PLANNED)',
        131: 'Company Vehicle has ongoing shipment',
        132: 'Company Driver has ongoing shipment',
      },
      assignShipperSalesAccess: {
        465: 'Email has been taken within the company',
      },
      common: {
        401: 'You do not have permission',
        500: 'Internal server error occurred',
        '018-5500': 'Internal server error occurred',
      },
      createBillOfLading: {
        94: 'Bill of Lading number has been used before',
      },
      createFirstMileInvoice: {
        422: 'Invoice number already exists in the system, invoice number must be unique',
      },
      createSmeSales: {
        465: 'Email has been taken within the company',
      },
      editBillOfLading: {
        94: 'Bill of Lading number has been used before',
        422: 'Store cannot be changed',
      },
      generateCompanyHandles: {
        34: 'Please use only alphanumeric and lowercase letters',
      },
      saveBrokerageVendor: {
        34: 'Please use only alphanumeric and lowercase letters',
      },
      saveCompanyDetails: {
        34: 'Please use only alphanumeric and lowercase letters',
      },
      saveCompanyVehicle: {
        1: 'Company not registered',
        2: 'Vehicle not registered',
        3: 'Failed to upload Vehicle Registration Certificate (STNK)',
        4: 'Failed to upload Vehicle Roadworthiness Certificate (KIR)',
        14: 'Vehicle already registered',
        15: 'Invalid vehicle type',
      },
      saveCompanyVehicles: {
        422: 'There are some invalid data',
      },
      saveCustomer: {
        1: 'Company not registered',
        5: 'Customer company not registered',
      },
      saveDeliveryOrder: {
        53: 'Invalid unloading location',
        54: 'Invalid loading location',
        63: 'Invalid unloading date',
        66: 'Invalid customer',
        422: 'Invalid CSV parameter/attributes',
        482: 'Hmm, you need to input this field if it is a single shipment type',
        483: 'Input a date and time format using DD-MM-YYYY HH:MM',
        484: 'Input an active Location registered in B-TMS',
        485: 'Input an active Location registered in B-TMS',
        486: 'Hmm, there is no shipper with this name.',
        488: 'Hmm, this truck type doesn’t have Overnight Fees registered for this Shipper and Shipment Type. Let’s pick another one.',
        490: 'Hmm..this Location doesn’t have a Loading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        491: 'Hmm..this Location doesn’t have an Unloading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        493: 'Deadline datetime must be in DD/MM/YYYY format.',
        494: 'Input a date and time format using DD-MM-YYYY HH:MM',
        497: 'Hmm all Group Loads must be posted to Marketplace, try again with "Yes" or "TRUE"',
        498: 'Hmm Per Unit Price Rate Type cannot be posted to Marketplace, try again with "No" or "FALSE"',
        499: 'Hmm, for a Group Load, the number of external identifiers must match the number of shipments (with each external identifier separated by semicolon) or must be left blank; for a non-Group Load, enter one external identifier or leave blank',
        501: 'Posted DO must have origin or posted price.',
        502: 'Fill in the price you want to post to the Marketplace or use our recommended price.',
        505: 'Hmm, this price_rate_type doesn’t match the Goods Capacity Unit. Pick "Per Route" (or "Route"), "Per {Capacity Unit}" (or "Capacity Unit")',
        506: 'Cannot post capacity unit.',
        507: 'Input YES/TRUE or NO/FALSE',
        508: 'Only posted DO have max transported bid price',
        509: 'Hmm the value here must match the Max Bid Limit (%)',
        510: 'Only posted DO have max bid limit percentage.',
        512: 'Hmm, enter 1 or leave blank to create a Group Load; enter more than 1 and less than 21 to create a Group Load',
        513: 'Hmm, the shipment type is not correct. Choose "f2w", "w2w", "f2dist", "w2dist", "f2mt", "w2depo", "f2depo", "w2mt", "mt", "gt", "multidrop", "multipickup", or "multidrop_multipickup".',
        516: 'Hmm, this truck type does not exist. Choose another truck type.',
        517: 'Hmm, this truck subtype does not exist. Choose another truck subtype.',
        518: 'Use 0 or positive numbers with a maximum of 8 decimal places.',
        519: 'Hmm, this capacity_unit doesn’t seem right. Pick "Kg", "CBM", "Unit"',
        523: 'Hmmm you must input a positive number up to 8 decimal points',
        524: 'Hmmm you must input a number from 0-100',
        525: 'Hmmm you must input a positive number up to 8 decimal points',
        526: 'Hmm, this Shipper doesn’t have this price_rate_type registered for this shipment. Let’s register the Shipper Price',
        530: 'Input valid shipment type',
        531: 'Invalid point type',
        532: 'Input a date and time format using YYYY-MM-DD HH:MM:SS',
        533: 'Hmm number of locations inputted must be equal to the number of pickup and dropoff points and they must be unique from each other',
        534: 'Input a Location registered in B-TMS',
      },
      saveEmployee: {
        26: 'Email or phone number already used',
      },
      savePartnershipPricing: {
        1001: 'Invalid Transporter',
        1002: 'Duplicate Pricing',
        1003: 'Partnership pricing not found',
        1004: 'Failed to delete partnership pricing',
      },
      saveWarehouseFee: {
        45: 'You already have a Location Fee with the same Fee Type, Shipment Type, Truck Type, and Truck Subtype',
        46: 'You already have a Location Fee with the same Fee Type, Shipment Type, Truck Type, and Truck Subtype',
      },
      uploadContainers: {
        422: 'Invalid container file',
      },
    },
    different_origin_and_destination: 'Origin and destination must be different',
    disbursement_form_wrong_input: 'Incorrect input field',
    error_code: {
      401: 'You do not have permission',
      445: 'Phone number is not registered yet',
      500: 'Internal server error occurred',
      4112: 'Incorrect password',
    },
    network: {
      500: 'Internal server error occurred',
      common: 'Check your internet connection again',
    },
    otp_required_please_retry: 'Verification code not found. Please try again',
    phone_number_already_registered: 'Number is already registered',
    status_code: {
      401: 'You do not have permission',
      500: 'Internal server error occurred',
      '018-5500': 'Internal server error occurred',
    },
    unexpected: 'An error occurred, the Kargo team is investigating this immediately',
    verification_code_mismatch: 'The verification code you entered does not match',
  },
  file_has_not_been_selected: 'File has not been selected',
  file_should_not_contain_mixed_file_types: 'Cannot upload documents with different file types',
  file_too_big_failed_upload: 'File size exceeds {{fileSize}}MB. Please check again',
  fill_account_details: 'Fill in Account Details',
  fill_bid_price: 'Fill in bid price',
  fill_company_details: 'Fill in Company Details',
  financing_adjustment_microcopy: 'Customer Price Change - Claim - Transfer Fee',
  first_or_last_shipment_points_type_invalid: 'The first shipment point must be loading and the last shipment point must be unloading',
  free_trial_banner: {
    contact_kargo: 'Contact Kargo',
    description_expired: 'To access all Kargo Nexus features, contact us to reactivate your account',
    description_expiring: 'To keep your account connected, <Link>contact Kargo</Link> to upgrade your account status',
    message_expire_today: 'Free trial ends today',
    message_expired: 'Your free trial has ended',
    message_expired_specific_company: 'Free trial for {{companyName}} has ended',
    message_expiring: 'Free trial will end on {{date}}',
    whatsapp_text: 'Hello Kargo Nexus team, I want to upgrade the status of my Kargo Nexus for Transporters account. Can you schedule a demo?',
  },
  general_failed_confirmed: '{{name}} failed to confirm',
  general_successfully_confirmed: '{{name}} successfully confirmed',
  general_successfully_saved: '{{name}} successfully saved',
  generic_add: 'Add {{name}}',
  generic_add_more: 'Add More {{name}}',
  generic_edit: 'Edit {{name}}',
  generic_select: 'Select {{name}}',
  generic_upload: 'Upload {{name}}',
  generic_send: 'Send {{name}}',
  generic_successfully_downloaded: '{{name}} successfully downloaded',
  grace_period_banner: {
    contact_kargo: 'Contact Kargo',
    description_expired: 'Contact us to reactivate your Kargo Nexus for Transporters account',
    message_expired: 'Your contract has expired',
    whatsapp_text: 'Hello Kargo Nexus team, I want to upgrade the status of my Kargo Nexus for Transporters account. Can you schedule a demo?',
  },
  income_details_displayed_here: 'Income details will be displayed here',
  ineligible_dof_shipment_status: 'Can only apply for Shipments whose POL has at least been received',
  insert_company_code: 'Enter company code',
  insert_date_to_calculate_actual_top: 'Enter the date to calculate Actual ToP',
  invalid_bank_account: 'Enter a valid account number',
  invalid_bank_account_holder_name: 'Enter a valid account holder name',
  invalid_phone_number: 'Must start with the + sign',
  invoice_cannot_create_number_description: 'Please select a customer first before using the automatic invoice number format.',
  invoice_cannot_create_number_warning: 'Cannot create invoice number format',
  invoice_create_number_question: 'Create invoice number format?',
  invoice_create_number_warning: 'You do not have an invoice number format for this customer',
  invoice_code_format_example: 'Example: If you enter 4 digits then the invoice code XX/XX/XX/<strong>0001</strong>',
  invoice_code_format_generation: 'Set the number of digits for the invoice code. Then the invoice code will be sorted automatically.',
  invoice_company_code_format_generation: 'Enter your company code to be included on the invoice number.',
  invoice_create_auto_format_generation: 'Create an invoice number format to generate invoice numbers automatically',
  invoice_customer_code_format_generation: 'Select a customer code as a marker that the invoice is addressed to a particular customer.',
  invoice_delete_dialog: 'The invoice template will be permanently deleted and cannot be restored',
  invoice_fee_changes_text: 'There are changes in the bill detail table.',
  invoice_note_placeholder: {
    [SHIPMENT_INVOICE_TYPE.ONCALL]: '',
    [SHIPMENT_INVOICE_TYPE.RENTAL]: 'Write special instructions or messages for customers',
  },
  invoice_pdf_should_only_one_file: 'Cannot upload more than one PDF document for the invoice',
  invoice_receipt_successfully_added: 'Invoice Receipt successfully added',
  invoice_setup_format: 'Set the format of the invoice components as needed',
  invoice_template_can_be_saved: 'This invoice format can be saved as a template and used for future invoices',
  invoice_template_successfully_deleted: 'Invoice Template Deleted',
  invoice_template_successfully_duplicated: 'Invoice Template Successfully Duplicated',
  invoice_template_successfully_saved: 'Invoice Template Successfully Saved',
  invoice_template_successfully_updated: 'Invoice Template Successfully Updated',
  invoice_will_be_shown_here: 'Invoice will be displayed here',
  kargo_will_process_your_do: 'Kargo will process your order. Please wait for the confirmation process.',
  leave_dof2_disbursed_form_warning: 'Filled disbursement details will be lost.',
  leave_form_warning: 'All information you have entered will be lost',
  leave_shipment_form_warning: 'This shipment will not be saved if you leave this page now.',
  leave_shipper_form_warning: 'The shipper will not be saved if you leave this page now.',
  leave_this_page_question: 'Leave this page?',
  max_x_shipment_selection_exceeded: 'The maximum number of Shipments that can be selected is {{x}} Shipments.',
  no_invoice_approved: 'No Approved Invoices',
  no_invoice_rejected: 'No Rejected Invoices',
  not_registered_with_kargo_dof_program: 'You are not registered with the Kargo DOF program',
  not_registered_with_kargo_podf_program: 'You are not registered with the Kargo PODF program',
  number_invoice_format_has_been_changed: 'Invoice number format successfully changed',
  number_invoice_format_has_been_deleted: 'Invoice number format successfully deleted',
  number_invoice_format_has_been_made: 'Invoice number format successfully created',
  offer_bidding_cancelled: 'Bidding for {{doNumber}} has been canceled',
  offer_bidding_non_slot_submitted: 'Offer for {{doNumber}} has been sent',
  offer_bidding_slot_submitted: 'Offer for {{amount}} Slot Unit has been sent',
  offer_successfuly_canceled: 'Offer for {{doNumber}} successfully canceled',
  offer_successfuly_submitted: 'You have successfully submitted an offer for {{doNumber}}',
  on_hold_reason: 'Submission is on hold, please contact Kargo admin',
  only_kargo_admin_can_edit: 'Only Kargo admin can change this information.',
  otp_instruction: 'Type new password, then input\nverification code that we send to',
  panthera_do_number_info: 'This Panthera DO number will be used to display GPS location in TMS Shipper.',
  password_doesnt_match: 'The two passwords you entered do not match',
  password_placeholder: 'Enter Password',
  password_successfully_changed: 'Password successfully changed',
  payment_project_save_as_draft_msg: 'The submission data you have entered will be saved as a draft, and you can continue the submission process later',
  pbi_registration: {
    i_have_read_and_agree: 'I have read and agree',
    terms_and_conditions: 'terms and conditions',
    terms_and_conditions_agreed: 'I have read and agree to the terms and conditions',
    verification_status_info_map: {
      [PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED]: 'Register to get access to Kargo Regular Loads',
      [PBI_PARTNERSHIP_STATUSES.PENDING]: 'We will notify you of your account verification status within a maximum of 2 working hours',
      [PBI_PARTNERSHIP_STATUSES.VERIFIED]: 'You have gained access to Kargo Regular Loads',
      [PBI_PARTNERSHIP_STATUSES.TRUSTED]: 'As a trusted user, you have gained access to Kargo Regular Loads and do not need to reconfirm orders',
      [PBI_PARTNERSHIP_STATUSES.SUSPENDED]: 'You cannot receive DO from PBI for 1x24 hours as your Panthera Biru Indonesia partner account has been suspended',
      [PBI_PARTNERSHIP_STATUSES.REJECTED]: 'Your verification was rejected. Please repeat the registration process to get access to Kargo Regular Loads',
      [PBI_PARTNERSHIP_STATUSES.BLOCKED]: 'You cannot receive DO from PBI for an indefinite period as your Panthera Biru Indonesia partner account has been blocked',
    },
  },
  pbi_invoice: {
    check_performance: 'Check Your Performance Here',
    download_invoice_detail: 'Download Details',
    get_performance_information: 'Get performance information for each delivery you make',
    invoice_filter_message: 'Complete the filter to display the list of DOs ready to be invoiced',
    revise_created: 'Send Invoice Revision',
    revise_invoice: 'Revise Invoice',
    send_digital_invoice: 'Send Digital Invoice',
    send_physical_document: 'Send Physical Invoice',
  },
  pbi_invoice_disabled_tooltip: {
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_CLAIM_APPROVAL]: 'Claim is being processed by PT Panthera Biru Indonesia',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_POD]: 'Proof of delivery has not been received by PT Panthera Biru Indonesia',
    [CUSTOMIZABLE_INVOICE_STATUSES.EXPIRED_STNK]: 'Vehicle Registration Certificate expired, please update PBI Fleet data.',
    [CUSTOMIZABLE_INVOICE_STATUSES.ONGOING_SHIPMENT]: 'Cannot be invoiced as it is still in progress',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_INVOICE_PAID]: 'Cannot be invoiced as awaiting Shipper payment',
  },
  phone_number_placeholder: 'Enter Phone Number',
  please_reconfirm_below: 'Please reconfirm for the Shipments below.',
  please_resubmit_rejected_documents: 'Please make a new submission and fix the rejected documents',
  please_resubmit_rejected_requests: 'Please make a new submission and correct the documents for rejected shipments',
  propose_verification_process_for_main_documents: 'Submit Verification Process for Main Documents?',
  ready_to_use: 'Ready to Use',
  reset_password_success: 'Your password has been successfully changed.',
  save_as_draft_and_exit: 'Save as draft and exit the submission process?',
  save_shipment_as_new_template: 'Save Shipment as New Template',
  save_shipment_as_type_template: 'Choose Shipment Template Storage Type',
  search_by_do_number: 'Search by DO number',
  search_by_invoice_number: 'Search by Invoice Number',
  search_by_license_plate: 'Search by License Plate',
  search_by_license_plate_or_kode_lambung: 'Search by License Plate or Hull Code',
  search_by_name: 'Search by name',
  search_customer: 'Search Customer',
  search_customer_v2: 'Search Customer',
  search_shipment_placeholder: 'Search by Shipment No., License Plate',
  search_shipment_number: 'Search Shipment Number',
  search_shipper: 'Search Shipper',
  search_transporter: 'Search Transporter',
  search_warehouse: 'Search Warehouse',
  select_customer: 'Select Customer ',
  select_truck_first_to_change_status: 'Select Truck first to change status',
  send_verification_code_failed: 'send_verification_code_failed',
  send_verification_code_success: 'Verification code sent.',
  shipment_allocation_choose_shipper_helper: 'The driver will receive a notification for the instructions for this shipment.',
  shipment_already_requested_for_financing: 'Shipment has already been submitted for fast payment',
  shipment_fee_successfully_added: 'Trucking fee successfully added.',
  shipment_invalid: 'Shipment is no longer valid',
  shipment_points_not_sorted: 'All loading and unloading times must be in order according to the loading and unloading location sequence',
  shipment_successfully_cancelled: 'Shipment successfully canceled',
  shipment_successfully_saved: 'Shipment successfully saved',
  shipment_template_successfully_saved: 'Shipment Template Successfully Saved',
  shipment_trace_messages: {
    cancelled: 'No travel history for this shipment',
    no_data: 'Failed to retrieve data for this shipment',
    ongoing: 'Shipment is in progress. View the shipment route and <Link>monitor the fleet</Link> now',
    planned: 'The shipment route will be visible when the shipment is in progress or completed',
  },
  shipper_already_included_on_selected_dof_request: 'Can only apply for one customer in one request',
  shipper_failed_to_add: 'Failed to add Company Shipper',
  shipper_failed_to_edit: 'Failed to edit Company Shipper',
  shipper_successfully_edit: 'Company Shipper edited',
  shipper_successfully_saved: 'Company Shipper added',
  signature_required_above_the_seal: {
    description: 'For invoice amounts greater than or equal to Rp5,000,000, a wet signature and stamp are required',
    title: 'Must be signed above the stamp',
  },
  skip_truck_requirement_question: 'Skip Truck Requirement?',
  some_file_download_failed: 'Some files could not be downloaded.',
  some_shipments_are_not_eligible_for_dof2: 'Shipments will not be counted in the 2nd disbursement. To disburse the remaining shipments, please make a new request when the shipments are eligible',
  status_successfully_changed: 'Status successfully changed',
  swift_payment_price_tnc: 'The disbursement amount will be calculated based on your evaluation and cooperation agreement',
  tick_signature: 'Tick Signature',
  transporter_incentive_page: {
    tooltip_label: 'Read incentive terms and conditions',
    dialog_header: 'Incentive Terms and Conditions',
    dialog_body: {
      1: '1. Vendors receive incentives from PT Panthera Biru Indonesia (<strong>Panthera</strong>) according to the following conditions',
      2: '2. Vendors receive incentives only from jobs that have the <strong>“Incentive”</strong> sign and have been completed well',
      3: '3. The incentive amount is calculated from the listed price based on the specified percentage',
      4: '4. The incentive payment process begins after Panthera receives proof of delivery (Proof of Delivery/POD)',
      5: '5. Incentive payment time is adjusted to Panthera’s decision',
      6: '6. The above provisions only apply if the Vendor has not or does not sign an agreement with Panthera',
      7: '7. If there is an agreement between the Vendor and Panthera, the terms in that agreement apply',
    },
  },
  top_performance_indicator: {
    bad_performance: 'Poor Performance',
    good_performance: 'Good Performance',
  },
  total_price_including_ppn: 'Total price includes VAT',
  total_price_including_tax: 'Shipping Costs Include VAT',
  unable_to_change_delivery_details: 'Cannot change delivery details as it is registered in the Fast Payment request',
  unable_to_change_disbursement_details: 'Cannot change the automatically generated Disbursement Information',
  update_shipment_status_warning: 'The sequence of statuses you selected is before the current shipment status. Are you sure you want to add status?',
  user_access_type_description_map: {
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: '-',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: '-',
    [USER_ACCESS_TYPE.FINANCE]: 'Access to view, modify, and perform anything without access to manage other employees.',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Access to view, modify, perform anything, and manage other employees.',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Access to only view shipping and payment information.',
    [USER_ACCESS_TYPE.PRICE_RESTRICTED_ACCESS]: 'Access to view shipping information without price or cost details.',
  },
  utilization_tooltip: {
    current_utilization_info: 'Current utilization is calculated from the average actual lead time compared to the company’s working days.',
    lead_time_info: 'The actual lead time starts when the driver departs for the first loading location until the delivery is complete. If there is a waiting time between each location, the waiting time is included.',
    company_working_days_info: 'Company working days are set in the company settings.',
  },
  utilization_upload_csv_steps: {
    1: '1. Download the CSV document template.',
    2: '2. Fill in the data according to the Target Utilization report per Route.',
    3: '3. Then, upload the CSV document with the filled-in template.',
  },
  utilization_upload_csv_confirm_message: 'Check the CSV document you uploaded below.',
  vehicle_is_broken: 'Vehicle is Having Issues',
  vehicle_is_in_good_condition: 'Vehicle is in Good Condition',
  we_will_send_temporary_password: 'We will send a temporary password to {{name}}’s phone number. {{name}} can log in to {{systemName}} and change the password.',
  welcome_to_nexus_for_transporters: 'Welcome to {{name}}!',
  welcome_to_nexus_for_transporters_description: 'Fill in your company details to start managing your business with {{name}}',
  you_can_proceed_to_this_stage_when_the_verification_complete: 'You can proceed to this stage when the Main Document verification is complete',
  panthera_max_20: '<strong>PT Panthera Biru Indonesia</strong> cannot process more than 20 Shipments in one Invoice.',
  choose_shipment_with_same_plate_color: 'Select DOs with the same plate color.',
  choose_shipment_with_same_disbursement_scheme: 'Select DOs with the same payment scheme.',
  price_are_zero: 'There are no costs that can be invoiced',
  invoice_min_amount_schema: 'As of {{startDate}} Kargo only accepts Invoices from {{transporterName}} with a minimum billing amount of {{minAmount}}. For more information, you can read',
  cannot_combine_vat: 'DO prices including VAT cannot be invoiced together with DOs not including VAT.',
  redirecting_to_expected_page: 'Redirecting to the appropriate page...',
  redirecting_to_specific_page: 'Redirecting to {{page}}...',
};
